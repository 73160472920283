@keyframes loaderSquareMove {
  0% {
    transform: translate(0,0)
  }
  10.5% {
    transform: translate(0,0)
  }
  12.5% {
    transform: translate(2rem,0);
  }
  23% {
    transform: translate(2rem,0);
  }
  25% {
    transform: translate(4rem,0);
  }
  35.5% {
    transform: translate(4rem,0);
  }
  37.5% {
    transform: translate(4rem,2rem);
  }
  48% {
    transform: translate(4rem,2rem);
  }
  50% {
    transform: translate(4rem,4rem);
  }
  60.5% {
    transform: translate(4rem,4rem);
  }
  62.5% {
    transform: translate(2rem,4rem);
  }
  73% {
    transform: translate(2rem,4rem);
  }
  75% {
    transform: translate(0,4rem);
  }
  85.5% {
    transform: translate(0,4rem);
  }
  87.5% {
    transform: translate(0,2rem);
  }
  98% {
    transform: translate(0,2rem);
  }
  to {
    transform: translate(0,0);
  }
}

.loader-container {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1001;
  margin-top: 350px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.loader-square-container {
  position: relative;
  transform: rotate(45deg);

  &:nth-of-type(2) {
    background-color: transparent;
    transform: rotate(45deg) translate(-5px, 5px);
  }

  .loader-square {
    animation: loaderSquareMove 10s ease-in-out infinite both;
    background-attachment: fixed;
    background-color: @colorMuted;
    background-position: center;
    background-size: cover;
    height: 1.5rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 1.5rem;

    &:nth-of-type(0) {
      animation-delay: 0s;
    }

    &:nth-of-type(1) {
      animation-delay: -1.4285714286s;
    }

    &:nth-of-type(2) {
      animation-delay: -2.8571428571s;
    }

    &:nth-of-type(3) {
      animation-delay: -4.2857142857s;
    }

    &:nth-of-type(4) {
      animation-delay: -5.7142857143s;
    }

    &:nth-of-type(5) {
      animation-delay: -7.1428571429s;
    }

    &:nth-of-type(6) {
      animation-delay: -8.5714285714s;
    }

    &:nth-of-type(7) {
      animation-delay: -10s;
    }
  }

  .loader-square-center {
    background-attachment: fixed;
    background-color: @btnActiveColor;
    background-position: center;
    background-size: cover;
    height: 1.5rem;
    left: 2rem;
    position: absolute;
    top: 2rem;
    width: 1.5rem;
  }
}