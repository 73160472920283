.tutorial-line {
  padding-bottom: 25px;
  text-align: center;
}

.tutorial-line div:first-child {
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .tutorial {
    width: 80% !important;
    max-width: 800px;
  }

  .tutorial-line {
    padding-bottom: 25px;
    display: flex;
    text-align: left;
  }

  .tutorial-line div:first-child {
    width: 70px;
    max-width: 50px;
    padding-bottom: 0;
  }
}