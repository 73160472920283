/* font */
@font-family-sans-serif: "Source Sans 3", Helvetica, Arial, sans-serif;

/* Form settings common */
@form-settings-btn-default-background-color: @colorMuted;
@form-settings-btn-default-border-color: @colorMuted;
@form-settings-btn-default-background-color-focus: @colorMuted;

/* Grid common */
@grid-item-disabled-background: #000000;
@grid-text-not-read-status-color: @colorDanger;  

/* Table common */
@table-label-background-color: #FFFFFF;
@table-label-offline-background-color: @colorDanger;
@table-label-offline-border-color: @colorDanger;
@table-label-danger-background-color: @colorDanger;
@table-label-warning-background-color: @colorWarning;
@table-label-success-background-color: @colorSuccess;

/* sweet alert 2 common */
@swal2-btn-confirm: @activeColor;
@swal2-btn-confirm-boxshadow: rgba(0, 0, 0, 0.0470588);
@swal2-btn-disabled-color: @colorMuted;
@swal2-btn-confirm-hover-background-color: @hoverColor; 
@swal2-btn-cancel-hover-background-color: @colorAccent3; 
@swal2-btn-cancel-background-color: @colorAccent3;

/* navbar */
/* Navbar override */
@navBarCollapseBreakpoint: 768px;