  /* Modal course content detail */
.modal-content-dialog {
  padding: 0;
  width: 100%;
}

.modal-content-table {
  display: table;
  height: 100%;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.modal-content-dialog .modal-content-col1 {
  padding: 0;
  background: @gridBackground;
  width: 282px;
  border-radius: 8px;
}
.modal-content-dialog .modal-content-col1 .modal-content-thumbnail img.portrait {
  width: 282px;
  border-radius: 8px 8px 0px 0px !important;         
}
.modal-content-dialog .modal-content-col1 .modal-content-footer {
  width: 282px;
  padding: 15px;
  float: left;
  background-color: @gridBackground;
  border-radius: 0px 0px 8px 8px;
}
@media (min-width: 370px) {
  .modal-content-dialog .modal-content-col1 .modal-content-thumbnail img.portrait {
    width: 336px;
  }
  .modal-content-dialog .modal-content-col1 .modal-content-footer {
    width: 336px;
  }
}
@media (min-width: 767px) {
  .modal-content-dialog .modal-content-col1 {
    display: table-cell;
    border-radius: 8px 0px 0px 8px;    
  }
  .modal-content-dialog .modal-content-col1 .modal-content-thumbnail img.portrait {
    width: 282px;
    border-radius: 8px 0px 0px 0px !important;      
  }
  .modal-content-dialog .modal-content-col1 .modal-content-footer {
    min-height: 205px;
    width: 282px;
    border-radius: 0px 0px 0px 8px;
  }
}
@media (min-width: 991px) {
  .modal-content-dialog .modal-content-col1 {
    width: 336px;
  }
  .modal-content-dialog .modal-content-col1 .modal-content-thumbnail img.portrait {
    width: 336px;         
  }
  .modal-content-dialog .modal-content-col1 .modal-content-footer {
    width: 336px;
  }
}

.modal-content-dialog .modal-content-col1 .modal-content-footer .modal-content-subtitle {
  margin-bottom: 2px;
}

.modal-content-dialog .modal-content-col1 .modal-content-footer .modal-content-evaluation-criteria {
  margin-top: 2px;
  margin-bottom: 0px;
  padding-bottom: 15px;
  color: @modal-content-criteria-color !important;
}

.content-startable {
  position: relative;
  display: flex;
}

.btn-group-content {
  margin: 0 auto;
}

.modal-content-dialog .modal-content-col1 .modal-content-footer .modal-content-btn-start {
  background-color: @modal-content-btn-start-background-color;
  border-color: @modal-content-btn-start-border-color;
  border-radius: 6px;
  width:180px;
  height:36px;     
}

.modal-content-dialog .modal-content-col1 .modal-content-footer .modal-content-btn-caret {
  background-color: @modal-content-btn-start-background-color;
  border-color: @modal-content-btn-start-border-color;
  border-radius: 0px 6px 6px 0px;
  width: 34px;
  height:36px;     
}
.modal-content-dialog .modal-content-col1 .modal-content-footer .modal-content-btn-dropdown {
  left: 33px;
  width: 180px;
  margin: auto;
  padding: 4px 0;
}
.modal-content-dialog .modal-content-col1 .modal-content-footer .modal-content-btn-start:hover,
.modal-content-dialog .modal-content-col1 .modal-content-footer .modal-content-btn-caret:hover {
  background-color: @modal-content-btn-start-hover-background-color; 
}

.modal-content-dialog .dropdown-menu {
  background-color: @background;
}
.modal-content-dialog .dropdown-menu > li > a {
  color: @color;
  cursor: pointer;
}
.modal-content-dialog .dropdown-menu > li > a:hover,
.modal-content-dialog .dropdown-menu > li > a:focus {
  color: #FFFFFF;
  background-color: @navbar-dropdown-menu-background;
}
.modal-content-dialog .dropdown-menu > li > .divider {
  background-color: @background;
}
.modal-content-dialog .dropdown-menu {
  font-size: 14px;
}

.modal-content-dialog .modal-content-col1 .modal-content-footer .modal-content-state {
  color: @modalContentStateColor;
  font-size: 16px;
  font-weight: bold;
}
.modal-content-dialog .modal-content-col1 .modal-content-footer .modal-content-last-access {
  color: @modalContentStateColor;
  font-size: 16px;
}
.modal-content-dialog .modal-content-col1 .modal-content-footer .modal-content-score {
  font-size: 28px;
  font-weight: 500;
  padding-bottom: 5px;
  padding-top: 0px;
  color:@color;
}

.modal-content-dialog .modal-content-col2 {
    display: block;
    float: left;
    width: 100%;
    padding-top: 15px;
    padding-left: 0px;
    padding-right: 0px;
}
@media (min-width: 767px) {
  .modal-content-dialog .modal-content-col2 {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    float: none;
    display: table-cell;
    vertical-align: top;
  }
  .modal-content-dialog .modal-content-description-container {
    min-height: 212px;
  }
}
@media (min-width: 991px) {
  .modal-content-dialog .modal-content-col2 {
    width: 504px;
  }
}

.modal-content-dialog .modal-content-col2 .modal-content-title {
  max-width: 448px;
}
.modal-content-dialog .modal-content-col2 .modal-content-title > p {
  margin-bottom: 0;
}

.modal-content-dialog .modal-content-col2 .modal-content-title > p > small {
  font-size: 85%;
  color: @colorHighlight;
}

@media (min-width: 991px) {
  .modal-content-dialog .modal-content-col2 .modal-content-title {
    max-width: 452px;
  }  
}

.modal-content-dialog .modal-content-col2 .modal-content-subtitle-item {
  display: inline-block;
  padding-right: 5px;
  padding-bottom: 5px;
}

.modal-content-dialog .modal-content-col2 .modal-content-duration {
  padding-top: 15px;  
}

/* Documents */
.modal-content-dialog .modal-content-col2 .modal-content-documents {
  background-color: @modal-content-documents-background-color;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
}

@media (min-width: 767px) {
  .modal-content-dialog .modal-content-col2 .modal-content-documents {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
  }
}

.modal-content-dialog .modal-content-col2 .modal-content-document {
  width: 100px;
  display: inline-block;
  vertical-align: top;
}

.modal-content-dialog .modal-content-col2 .modal-content-document-wrapper {
  position: relative;
}
.modal-content-dialog .modal-content-col2 .modal-content-document-wrapper a {
  text-decoration: none;
}
.modal-content-document-icon {
  transition: background-color, .2s, ease-out;
  animation-duration: .5s !important;
  color: @modal-content-documents-color;
}
.modal-content-dialog .modal-content-col2 .modal-content-document-wrapper:hover .modal-content-document-icon,
.modal-content-document-icon:hover {
  color: @modal-content-documents-color-hover;
  cursor: pointer;
}
.modal-content-dialog .modal-content-col2 .modal-content-document-wrapper:active .modal-content-document-icon,
.modal-content-document-icon:active {
  color: @modal-content-documents-color;
  cursor: pointer;
}
.modal-content-document-certificat-icon {
  color: @modal-content-certificat-color;
}
.modal-content-dialog .modal-content-col2 .modal-content-document-wrapper:hover .modal-content-document-certificat-icon,
.modal-content-document-certificat-icon:hover {
  color: lighten(@modal-content-certificat-color, 10%);
}
.modal-content-dialog .modal-content-col2 .modal-content-document-wrapper:active .modal-content-document-certificat-icon,
.modal-content-document-certificat-icon:active {
  color: @modal-content-certificat-color;
}

.modal-content-dialog .modal-content-col2 .modal-content-document-title {
  font-weight: normal;
  color: @colorOnDark;
  cursor: pointer;
}

.modal-content-dialog .modal-content-col2 .modal-content-document-title p {
  line-height: 1em;
  color: @colorOnDark;
  padding-top: 10px;
}

.panel-document-inactive {
    position: absolute;
    cursor: pointer;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 99;
}