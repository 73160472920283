.btn {
  font-size: 16px;
}

/* Buttons */
.btn-main {
  width: 100%;
  background-color: @btnPrimaryColor;
  border-color: @btnPrimaryBorderColor;
}

.btn-main:hover {
  background-color: @btnPrimaryColorHover;
  border-color: @btnPrimaryBorderColorHover;
}

.btn-main.active,
.btn-main:active,
.btn-main.focus,
.btn-main:focus,
.btn-main:active:focus,
.btn-main:active:hover {
  background-color: @btnPrimaryColorActive;
  border-color: @btnPrimaryBorderColorActive;
}

@media (min-width: @screen-xs) {
  .btn-main {
    max-width: 304px;
  }
}
@media (min-width: @screen-sm) {
  .btn-main {
    max-width: 380px;
  }
}

.btn-main-short {
  width: 270px;
}

/* Button group with custom theme */
.custom-btn-group .btn-default {
  background-color: @btnBackground;
  border-color: @btnBorderColor;
  color: @color;
}
.custom-btn-group .btn-default:focus,
.custom-btn-group .btn-default.focus {
  color: @color;
  background-color: @btnBackground;
  border-color: @btnBorderColor;
}
.custom-btn-group .btn-default:hover {
  color: #FFFFFF;
  background-color: @btnBackgroundHover;
  border-color: @btnBorderColor;
}
.custom-btn-group .btn-default:active, 
.custom-btn-group .btn-default.active, 
.custom-btn-group .open>.dropdown-toggle.btn-default {
  background-color: @btnBackgroundActive;
  border-color: @btnBorderColor;
  color: #FFFFFF;
}
.custom-btn-group .btn:focus,
.custom-btn-group .btn:active:focus,
.custom-btn-group .btn.active:focus,
.custom-btn-group .btn.focus,
.custom-btn-group .btn:active.focus,
.custom-btn-group .btn.active.focus {
  outline: none;
  outline-offset: 0;
}

.btn-group-prefix {
  float:left; 
  transform: translate(0, 40%); 
  padding-left: 13px; 
}
.btn-group-prefix > span {
  color: @colorPlaceHolder;
  font-size: 16px;
}

/* Buttons Aide */
a.link-button {
  cursor: pointer;
  color: @typeform-color;
  border: @typeform-border-color-width solid @typeform-border-color;
  background-color: @typeform-background-color;
  box-shadow: 0px 2px 4px 0px @panel-item-shadow;
  border-radius: 3px;
  font-size: 1.0rem;
  line-height: 1.25rem;
  border-radius: 8px;
  height: auto;
  width: 250px;
  display: block;
  margin: auto;
  padding: 15px 15px 10px 15px;
  text-align: center;
  transition: background-color, .2s, ease-out;
  text-decoration: none;
  box-sizing: border-box;
}

a.link-button:hover, a.link-button:focus {
  text-decoration: none !important;
  color: @typeform-hover-color;
  background-color: @typeform-background-hover-color;
}

.link-button-inactive {
  margin: 0;
  width: 250px;
  position: absolute;
  top: 0px;
  bottom: 30px;
  left: 49%;
  transform: translate(-50%);
  background-color: black;
  opacity: 0.4;
  border-radius: 8px;
}

@media (min-width: 991px) {
  .link-button-inactive {
    margin-left: 3px;
  }
}

/* button clear */
.btn-link {
  font-size: 1em;
}