@media (min-width: @screen-lg) {
  .row.center-content {
    width: max-content;
    margin: 0 auto;
  }
}

.passport-link a:link,
.passport-link a:visited,
.passport-link a:active {
  color: @passportLinkColor;
}

.passport-link a:hover {
  color: @passportLinkHoverColor;
}