/* Colorized nav-bar from:
 http://work.smarchal.com/twbscolor/css/242527343638ffffffeeeeee1 */
 
.navbar-brand {
    padding: 0px;
    margin: 0px;
}

.navbar-logo-scale-50 {
  transform: scale(0.5, 0.5);
}

.navbar-default {
  padding: 10px 0px 20px 0px;
  margin: 0px;
  background-color: transparent;
  background: transparent;
  border-color: transparent;
}

@media screen and (max-width: 768px) {
  .navbar-default .navbar-collape-background {
    background-color: @background;  
  }
}

.navbar-default .navbar-brand {
  color: @color;
}
.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
  color: @hoverColor;
}
.navbar-default .navbar-text {
  color: @color;
}
.navbar-default .navbar-nav > li > a {
  color: @color;
  cursor: pointer;
}
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: @hoverColor;
}
.navbar-default .navbar-nav > li > a.nav-selected {
  color: @activeColor;
}
.navbar-default .navbar-nav > li > .dropdown-menu {
  background-color: @background;
}
.navbar-default .navbar-nav > li > .dropdown-menu > li > a {
  color: @color;
  cursor: pointer;
}
.navbar-default .navbar-nav > li > .dropdown-menu > li > a:hover,
.navbar-default .navbar-nav > li > .dropdown-menu > li > a:focus {
  color: #FFFFFF;
  background-color: @navbar-dropdown-menu-background;
}
.navbar-default .navbar-nav > li > .dropdown-menu > li > .divider {
  background-color: @background;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: @hoverColor;
  background-color: lighten(@background, 3%);
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: @hoverColor;
  background-color: @background;
}
.navbar-default .navbar-toggle {
  border-color: @background;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: lighten(@background, 5%);
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: @color;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: @color;
}
.navbar-default .navbar-link {
  color: @color;
}
.navbar-default .navbar-link:hover {
  color: @hoverColor;
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: @color;
    cursor: pointer;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: @hoverColor;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: @hoverColor;
    background-color: lighten(@background, 3%);
  }
}

/* Autre block pour nav-bar */
.navbar {
  font-size: 19px;
}

.nav>li>a {
  padding: 15px 10px;
  margin: 0px;
}

.navbar-collapse {
  margin: 25px 0px 0px 0px; /* add space above collapsed menu */
}

.navbar-collapse .nav>li>a {
  padding: 10px 10px;
}

@media (min-width: 992px) {
  .navbar {
    font-size: 19px;
  }
  .nav>li>a {
    padding: 15px 15px;
  }
  .navbar-collapse .nav>li>a {
    padding: 10px 15px;
  }
}

.nav > li > a:hover,
.nav > li > a:focus {
  background-color: @navbar-dropdown-menu-background;
  border-top-color: @background;
  border-left-color: @background;
  border-right-color: @background;
}

.navbar-default .navbar-nav .open .dropdown-menu {
    font-size: 18px;
}

.container>.navbar-header {
    height: 60px;
}

.container>.navbar-header, 
.container-fluid>.navbar-header, 
.container>.navbar-collapse, 
.container-fluid>.navbar-collapse {
    margin-left: 0px;
}

.navbar-title {
    margin-top: 10px;
}
@media (min-width: 768px) {
  .navbar-title {
    padding-left: 5px;
  }
}
@media (max-width: 768px) {
  .navbar-title {
    padding-left: 20px;
  }
}

.nav-memo-count-div {
  float: right; 
  padding-left: 5px;
}


/* Collaping controls */

@media (max-width: @navBarCollapseBreakpoint) {
  .navbar-header {
      float: none;
  }
  .navbar-left,.navbar-right {
      float: none !important;
  }
  .navbar-toggle {
      display: block;
  }
  .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
      display: none!important;
  }
  .navbar-nav {
      float: none!important;
      margin-top: 7.5px;
  }
  .navbar-nav>li {
      float: none;
  }
  .navbar-nav>li>a {
      padding-top: 10px;
      padding-bottom: 10px;
  }
  .collapse.in{
      display:block !important;
  }
}