.fa-stack-combine-custom {
  position: relative;
  display: inline-block;
  width: 1.65em;
  height: 1em;
  text-align: left;
}

.fa-stack-main-icon {
  position: absolute;
}

.fa-stack-invite-sign {
  margin-left: 1.65em;
  font-size: 0.75em;
}

.fa-stack-tag-sign {
  margin-left: 1.65em;
  font-size: 0.70em;
}

.fa-stack-filter {
  position: relative;
  display: inline-block;
  width: 1.1em;
  height: 1em;
  text-align: left;
}

.fa-stack-filter-times-sign {
  margin-left: 1.30em;
  font-size: 0.70em;
}

.fa-6x {
  font-size: 6em;
}