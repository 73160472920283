/* bootstrap selectpicker button, adjust the caret */
.bootstrap-select .bs-caret {
  color: @colorOnLight; 
}

.bootstrap-select.select-languages,
.bootstrap-select .select-languages {
  height: 46px;
  font-size: 16px;
}

.bootstrap-select .dropdown-menu li small {
  color: @activeColorOnLight;
}

.bs-searchbox .form-control {
  margin-left: 0 !important;
}

.bs-filter-options-group {
  padding: 5px 8px 8px 8px !important;
}

.bs-filter-options-group .btn-group-vertical  {
  width: 100%;
}

.bs-filter-options-group label {
  text-align: left;
}