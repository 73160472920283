/* Ng animate: fade + bubble */
.animate-fade-bubble.ng-enter {
  transition:0.5s linear all;
  opacity:0;

}
.animate-fade-bubble.ng-enter.ng-enter-active {
  -webkit-animation: pulse 0.5s;
          animation: pulse 0.5s;  
  opacity:1;
}
.animate-fade-bubble.ng-leave {
  -webkit-animation: pulse 0.5s;
          animation: pulse 0.5s;  
  transition:0.5s linear all;
  opacity:1;
}
.animate-fade-bubble.ng-leave.ng-leave-active {
  opacity:0;
}

/* Ng animate: face */
.animate-fade.ng-enter {
  transition:0.5s linear all;
  opacity:0;
}
.animate-fade.ng-enter.ng-enter-active {
  opacity:1;
}
.animate-fade.ng-leave {
  transition:0.5s linear all;
  opacity:1;
}
.animate-fade.ng-leave.ng-leave-active {
  opacity:0;
}

/* Ng animate: show / hide */
.animate-show-fade {
  transition:all linear 0.4s;
}
.animate-show-fade.ng-hide {
  opacity:0;
}