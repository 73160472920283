.form-control {
  font-size: 16px;
  height: 36px;
}

.form-control + .input-group-btn>button {
  height: 36px;
}

.form-control-width-25 {
  width: 25%;
}

.help-block-info {
  color: @color;
}

.help-block-tip {
  color: @colorSuccess;
}

.help-block-warn {
  color: @colorWarning;
}