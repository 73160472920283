/* Centered title and a line on both sides */
.lined-title-center {
    position: relative;
    font-size: 23px;
    padding-bottom: 1px;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    color: @colorMuted;
    user-select: none;
}
.lined-title-center:before, .lined-title-center:after {
    position: absolute;
    top: 52%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: '\a0';
    background-color: @colorMuted;
    border: 0;
}
.lined-title-center:before {
    margin-left: -51.5%;
    text-align: right;
}
.lined-title-center:after {
    margin-left: 15px;
}

/* Left title and a line on right */
.lined-title-left {
    position: relative;
    font-size: 23px;
    padding-bottom: 1px;
    z-index: 1;
    overflow: hidden;
    text-align: left;
    color: @colorMuted;
    user-select: none;
}

.lined-title-left:hover {
    color: @colorMuted;
}

.lined-title-left:after {
    position: absolute;
    top: 52%;
    overflow: hidden;
    width: 100%;
    height: 1px;
    content: '\a0';
    background-color: @colorMuted;
    margin-left: 15px;
    border: 0;
}

/* Lined separator */
.lined-separator {
    position: relative;
    font-size: 23px;
    padding-bottom: 1px;
    z-index: 1;
    overflow: hidden;
    text-align: left;
    color: @colorMuted;
}

.lined-separator:hover {
    color: @colorMuted;
}

.lined-separator:after {
    position: absolute;
    top: 10%;
    overflow: hidden;
    width: 100%;
    height: 1px;
    content: '\a0';
    background-color: @colorMuted;
    margin-left: 0;
    border: 0;
}