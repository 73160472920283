/* bootstrap modal: mainly used by datatables */

.modal-content {
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width:inherit;
    height:inherit;
    margin: 0 auto;    
    background-color: @modalContentBackground;
    border-color: @modalContentBorderColor;
    border: 0;
    color:@color;
    border-radius: 8px;
}

.modal-header, .modal-footer {
  border: none;
}

.modal-header .close,
.modal-footer .close {
  font-size: 36px;
  line-height: 36px;
  font-weight: 500;
  opacity: 1;
  color: @modal-close-color;
  -webkit-transition: color .1s ease;
          transition: color .1s ease;
  text-shadow: 0 1px 0 @modal-close-shadow-color;
}

.modal-header .close:focus, 
.modal-header .close:hover,
.modal-footer .close:focus, 
.modal-footer .close:hover {
  opacity: 1;
  color: @modal-close-hover-color; 
}

.alert-dismissable .close, .alert-dismissible .close {
    top: -8px;
    right: -32px;
}

.modal-header .close {
  margin-top: -6px !important;
}

.close .close-icon {
  color: @colorOnDark;
}