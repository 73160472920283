.form-settings {
  max-width: 600px;
}

.form-settings .btn-block {
  padding-top: 5px;
}

.form-settings .form-group {
  margin-bottom: 0px;
}

.capital-first {
    text-transform: capitalize;
}

.strategy-logo {
  width: 100px;
}