:root {
  // override var from datatables.bootstrap
  --dt-row-selected: @table-dataTable-selected-background-color-rgb;
  --dt-row-selected-text: @color-rgb;
  --dt-row-selected-link: @activeColor-rgb;
}

.pagination>li>a,
.pagination>li>span {
  background-color: @table-button-background-color;
  border-color: @table-button-border-color;
  border-width: @table-button-border-width;
  color: @color;
  z-index: 3;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  color: #fff;
  background-color: @btnBackgroundActive;
  border-color: @table-button-border-color;
}

.pagination>.active>a,
.pagination>.active>span {
  z-index: 3;
}

.pagination>.active>a:focus,
.pagination>.active>span:focus {
  color: #fff;
  background-color: @btnBackgroundActive;
  border-color: @table-button-border-color;
  z-index: 5;
}

.pagination>li>a:hover {
  background-color: @table-button-hover-color;
  border-color: @table-button-border-color;
  color: #FFFFFF;
}

.pagination>li>a:focus {
  background-color: @btnBackgroundActive;
  border-color: @table-button-border-color;
  color: #FFFFFF;
  outline-color: transparent;
  z-index: 5;
}

.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
  color: @table-button-disable-color;
  background-color: @table-button-background-color;
  border-color: @table-button-border-color;
  cursor: not-allowed;
  outline-color: transparent;
  z-index: 5;
}

/* DataTables */
.table {
  width: 100% !important;
}

.table-header {
  padding-top: 15px;
}

.dt-layout-row > .dt-layout-full > .dt-buttons {
  margin-left: inherit !important;
}

.table-top>.dt-buttons {
  float: left;
  margin-top: -3px;
}

@media (min-width: @screen-sm) {
  .table-top>.dt-buttons {
    position: absolute;
    width: 100%;
    padding-right: 30px;
  }
}

.table-top>.dt-buttons>.btn-icon {
  width: 35.5px;
}

.table-top>.dt-buttons>.btn-icon-stack {
  padding-left: 6px;
}

.table-top>.dt-buttons>.btn-left {
  float: left;
}

.table-top>.dt-buttons>.btn-right {
  float: right;
}

@media (min-width: @screen-lg) {
  .table-top>.dt-buttons {
    margin-top: 15px;
  }
}

// Tweak the buttons and paging rows
div.dt-container div.row:nth-child(3) > div:first-child {
  text-align: left;
}

div.dt-container div.row:nth-child(4) > div:first-child {
  margin-top: 15px;
}
div.dt-container div.row:nth-child(5) > div:first-child {
  margin-top: 10px;
}

.dt-paging {
  line-height: 0.61;
}

.dt-paging-button > a {
  font-size: 16px;
}

// responsive toggle button

html.dark table.dataTable > tbody > tr > td.dtr-control:before,
html[data-bs-theme=dark] table.dataTable > tbody > tr > td.dtr-control:before {
  border-left-color: fade(@btnActiveColor, 75%) !important;
}
html.dark table.dataTable > tbody > tr > td.dtr-control.arrow-right::before,
html[data-bs-theme=dark] table.dataTable > tbody > tr > td.dtr-control.arrow-right::before {
  border-right-color: fade(@btnActiveColor, 75%) !important;
}
html.dark table.dataTable > tbody > tr.dtr-expanded > td.dtr-control:before,
html.dark table.dataTable > tbody > tr.dtr-expanded > th.dtr-control:before,
html[data-bs-theme=dark] table.dataTable > tbody > tr.dtr-expanded > td.dtr-control:before,
html[data-bs-theme=dark] table.dataTable > tbody > tr.dtr-expanded > th.dtr-control:before {
  border-top-color: fade(@btnActiveColor, 75%) !important;
}

table.dataTable > tbody > tr > td.dtr-control:before {
  border-left-color: fade(@btnActiveColor, 75%) !important;
}
table.dataTable > tbody > tr > td.dtr-control.arrow-right::before {
  border-right-color: fade(@btnActiveColor, 75%) !important;
}
table.dataTable > tbody > tr.dtr-expanded > td.dtr-control:before,
table.dataTable > tbody > tr.dtr-expanded > th.dtr-control:before {
  border-top-color: fade(@btnActiveColor, 75%) !important;
}


/*  DT loader */
.dt-processing {
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: 140px !important;
  margin-left: -70px !important;
  padding: 0.75em 0 !important;
}

div.dt-processing>div:last-child>div {
  background: @btnActiveColor;
}

/* table buttons */
div.dt-button-collection {
  width: auto;
  padding: 0;
  background-color: @table-button-background-color !important;
  border: none !important;
  margin-top: 0 !important;
  margin-bottom: 2px;
}

.dt-button-collection > .dropdown-menu {
  margin-top: 0 !important;
}

.dt-button-actions>.btn {
  width: 36px;
  height: 36px;
}

div.dt-buttons.dropdown {
  position: relative;
}

.table-top > .dt-buttons > a,
.dt-buttons.btn-group > .btn,
.dt-button-actions > .dropdown-menu,
.dt-button-actions > .dropdown-menu > li > a,
.dt-button-collection > .dropdown-menu,
.dt-button-collection > .dropdown-menu > .dt-button {
  background-color: @table-button-background-color;
  border-color: @table-button-border-color;
  border-width: @table-button-border-width;
  color: @color !important;
  cursor: pointer;
}

.dt-button-actions > .dropdown-menu > li.dt-button-danger > a {
  color: @colorDanger !important;
}

.table-top > .dt-buttons > a:hover,
.dt-buttons.btn-group > .btn:hover,
.dt-button-collection > .dropdown-menu > .dt-button:hover,
.dt-button-collection > .dropdown-menu > .dt-button > a:hover,
.dt-button-actions > .dropdown-menu > li > a:hover {
  background-color: @table-button-hover-color;
  color: #FFFFFF !important;
}

.dt-button-actions>.btn>i.fa-ellipsis-h {
  width: 16px;
}

.table-top > .dt-buttons > a.disabled,
.dt-buttons.btn-group > .btn.disabled,
.dt-buttons.btn-group > .btn.disabled:hover,
.dt-buttons.btn-group > .btn.disabled:focus,
.dt-button-collection > .dropdown-menu > .dt-button.disabled,
.dt-button-collection > .dropdown-menu > .dt-button.disabled:hover,
.dt-button-collection > .dropdown-menu > .dt-button.disabled:focus,
.dt-button-collection > .dropdown-menu > .dt-button.disabled > a,
.dt-button-collection > .dropdown-menu > .dt-button.disabled > a:hover,
.dt-button-collection > .dropdown-menu > .dt-button.disabled > a:focus,
.dt-button-actions > .dropdown-menu > li > a.disabled,
.dt-button-actions > .dropdown-menu > li > a.disabled:hover 
{
  color: @table-button-disable-color !important;
  background-color: @table-button-background-color;
  border-color: @table-button-border-color;
  cursor: not-allowed;
}

.dt-buttons>.dropdown-menu>li>a:hover,
.dt-buttons>.dropdown-menu>li>a:focus {
  background-color: @table-button-hover-color;
  color: #FFFFFF !important;
}

.table .dropdown-menu > li.divider,
.dt-button-collection > .dropdown-menu > li.divider {
  background-color: @dividerColorLighter;
}

/* dt info text */
.dataTables_info {
  text-align: center !important;
  white-space: normal !important;
  padding-left: 15px;
  padding-right: 15px;
}

div.dt-button-info {
  background-color: @table-button-background-color;
  border: 2px solid @table-button-border-color;
  color: @color;
}

div.dt-button-info h2 {
  border-bottom: 1px solid @table-button-background-color;
  background-color: @table-button-border-color;
}

/* Datatable editor overides */
table.dataTable tbody>tr.selected,
table.dataTable tbody>tr>.selected {
  background-color: @table-dataTable-selected-background-color;
  color: @color;
  color: rgb(var(--dt-row-selected-text));
}

table.dataTable tbody .editable .DTE_Field_InputControl>input {
  margin-bottom: -7px;
  margin-left: -6px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 6px;
  padding-right: 6px;
  color: #242527;
  font-weight: 600
}

@media (min-width: @screen-sm) {
  table.dataTable tbody .editable .DTE_Field_InputControl>input {
    margin-top: -9px;
    margin-left: -13px;
    padding-left: 12px;
    padding-right: 12px;
    width: auto !important;
  }
}

div.DTE_Field div.multi-value,
div.DTE_Field div.multi-restore {
  color: @colorOnLight;
  text-align: center;
}

/* Table grid line */
.table>thead>tr>th {
  vertical-align: bottom;
  border-bottom: 2px solid @table-border-color;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid @table-border-color
}

.table>tbody>tr>td p,
.table>tbody>tr>td p {
  margin: 0;
  line-height: 1.25;
}

.table>tbody>tr>td p em.fa,
.table>tbody>tr>td p i.fa {
  font-size: 0.85em;
  text-align: left;
  width: 1.28571429em;
}

/* Table labels */
.table-label {
  display: inline-block;
  min-width: 100px;
  height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: @table-label-background-color;
  border-radius: 4px;
}

.table-thumbnail {
  max-width: 100px;
}

.label-online {
  background-color: @table-label-online-background-color;
  border-color: @table-label-online-border-color;
}

.label-offline {
  background-color: @table-label-offline-background-color;
  border-color: @table-label-offline-border-color;
}

.label-default {
  background-color: @table-label-default-background-color;
}

.label-warning {
  background-color: @table-label-warning-background-color;
}

.label-light {
  font-weight: 500;
}

/* Table editor */
.editor-datetime {
  color: @table-editor-datetime-color;
}

/* Misc */
.vertical-align-middle {
  vertical-align: middle;
}

/* Badges */
.table-badge {
  padding-top: 5px;
}

.badge-success {
  background-color: @table-label-success-background-color !important;
}

.badge-danger {
  background-color: @table-label-danger-background-color !important;
}

.badge-warning {
  background-color: @table-label-warning-background-color !important;
}

.bubble {
  animation: pulse 0.5s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

div.dataTables_processing>div:last-child {
  display: none;
}

div.dataTables_wrapper div.dataTables_processing {
  background-color: transparent;
  border: none;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  box-shadow: none;
  z-index: 1001;
}

body.dt-print-view {
  background-color: transparent;
  background-image: none;
  color: @colorOnLight;
}

.dt-buttons.btn-group>.btn:not(:first-child).btn:not(:last-child) {
  border-radius: 0;
}

.dt-buttons.btn-group>.btn.dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
}

.dt-buttons.btn-group .btn {
  margin-left: -1px;
}

.dt-buttons.btn-group>.btn.dropdown-toggle.last-dropdown {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

div.modal div.DTE div.DTE_Form_Error {
  float: inherit;
  text-align: center;
  font-size: 18px;
  color: @modal-error-text-color;
}

div.DTE div.DTE_Form_Buttons {
  display: flex;
  justify-content: center;
}

div.DTE_Form_Buttons>.btn.btn-default {
  background-color: @form-confirm-background-color;
  border-color: @form-confirm-border-color;
  transition: box-shadow 0.4s;
  box-shadow: 0 0 0 3px transparent;
  border: 0;
  font-size: 1.0625em;
  border-radius: 0.25em;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  margin: 0.3125em;
  padding: 0.625em 2em;
}

div.DTE_Form_Buttons>.btn.btn-default:hover {
  background-color: darken(@form-confirm-background-color, 10%);
  border-color: darken(@form-confirm-border-color, 12%);
}

div.DTE_Form_Buttons>.btn.btn-default:focus,
div.DTE_Form_Buttons>.btn.btn-default:hover {
  box-shadow: 0 0 0 3px fade(@form-confirm-background-color, 50%);
}

div.DTE_Form_Buttons>.btn.btn-default:focus {
  outline: none;
}

div.DTE_Form_Buttons>.btn.btn-default:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

div.DTE.DTE_Action_Edit,
div.DTE_Action_Create,
div.DTE_Action_Remove {
  font-size: 16px;
}

div.DTE_Form_Content .control-label {
  margin-bottom: .4em;
}

div.DTE_Form_Content .control-label-left {
  text-align: left;
}

table.dataTable td.dt-type-date,
table.dataTable td.dt-type-numeric,
table.dataTable th.dt-type-date,
table.dataTable th.dt-type-numeric {
  text-align: left;
}

table.dataTable tbody .dtr-control .td-ordering {
  background-color: @table-dataTable-trControl-background-color;
  color: @colorMutedLight;
  text-align: center;
  cursor: move;
  padding-top: 5px;
  padding-bottom: 5px;
}

div.DTE_Body div.DTE_Body_Content div.DTE_Field.full-block label,
div.DTE_Body div.DTE_Body_Content div.DTE_Field.full-block>div {
  width: 100%;
  flex: 0 0 100%;
  text-align: left;
}


@media (min-width: @screen-lg) {
  .DTE_Field_Type_selectPicker>div[data-dte-e="input"] {
    padding-top: 5px;
  }

  .DTE-field-is-multiline>div[data-dte-e="input"] {
    padding-top: 12px;
  }
}

// Position radios and checkboxes better
div.DTE_Field_Type_radio div.controls label,
div.DTE_Field_Type_checkbox div.controls label,
div.DTE_Field_Type_checkboxFlex div.controls label {
  margin-left: 0.75em;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: 400;
}

div.DTE_Field_Type_radio div.controls input[type="radio"],
div.DTE_Field_Type_checkbox div.controls input[type="checkbox"],
div.DTE_Field_Type_checkboxFlex div.controls input[type="checkbox"] {
  margin: 0;
}