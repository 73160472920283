/* TODO - Legacy code? */
.icon-title {
  transform: rotate(45deg);
}
/* END */

/* View */
.default-view {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 50px;
  padding-left: 0px;
  margin: 0 auto;
  height: 100%;
}

.memos-view,
.dashboard-view,
.kb-view {
  padding-bottom: 200px;
}

.library-view {
  padding-bottom: 160px;
}

@media (max-width: @screen-sm-max) {
  .memos-view,
  .dashboard-view,
  .library-view,
  .kb-view {
    padding-bottom: 320px;
  }
}

.library-view > .row ,
.contents-view > .row {
  margin-right: 0;
  margin-left: 0;
}

.help-view {
  padding: 15px 15px 200px 15px;
  margin: 0 auto;
  height: 100%;
}
.help-view p {
  font-size: 18px;
}

/* Sample */
.sample-show-hide {
  -webkit-transition: all 0.5s linear;
     -moz-transition: all 0.5s linear;
      -ms-transition: all 0.5s linear;
          transition: all 0.5s linear;
}

.sample-show-hide.ng-hide {
  opacity: 0;
}

.presentation-header {
    padding-bottom: 30px;
}

.presentation-footer {
    padding-top: 30px;
}

/* text representing an ID besides a main name identifier string*/
.text-id {
  color: @activeColor;
  padding-left: 0.5em;
}

.text-sub {
  color: @activeColor;
}

@media (min-width: @screen-lg) {  
  .tos p {
    font-size: 16px;  
  }
}