.kb-view-article {
  font-size: 1.285712em;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 20px 0;
}

@media (min-width: @screen-sm) {
  .kb-view #kb-article-search-input {
    font-size: 20px;
  }

  .kb-view-article {
    padding: 0 60px;
  }
}
@media (min-width: @screen-md) {
  .kb-view-article {
    padding: 0 160px;
  }
}

.kb-view-article .video-container {
  margin: 0 auto;
  border: 0;
  padding: 0;
  width: 100%;
}

.kb-view-article .video-container iframe {
  width: 100%;
  height: 100%;
}

.kb-view-swal2-container {
  overflow-x: auto;
  flex-direction: unset;
  display: block;
}

.kb-view-swal2-popup-full-width {
  width: 100% !important;
  margin: auto;
}

@media (min-width: @screen-lg) {
  .kb-view-swal2-popup-full-width {
    width: @screen-lg !important;
  }
}

@media (min-width: @screen-xl) {
  .kb-view-swal2-popup-full-width {
    width: @screen-xl !important;
  }
}