.panel,
.panel a {
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px @panel-item-shadow;
}

.panel a {
  transition: color 0.4s;
}

.panel .panel-heading {
  border-radius: 6px 6px 0px 0px;
  color: @colorOnDark;
  transition: background-color 0.4s;
}

.panel .panel-heading-console {
  height: 80px;
}

.panel .panel-heading .text-container {
  padding-left: 0px;
}

.panel .panel-heading .main-text {
  font-size: 40px;
  height: 48px;
}

.panel .panel-heading .secondary-text {
  font-size: 26px;
  height: 32px;
}
.panel .panel-heading .secondary-text span.fa {
  font-size: 24px;
}
.panel .panel-heading .secondary-text i {
  font-size: 14px;
}

.panel .panel-heading .main-sub-text,
.panel .panel-footer .explain-text {
  font-size: 16px;
}

@media (min-width: @screen-lg) {
  .panel .panel-heading .main-sub-text {
    font-size: 14px;
  }
}
@media (min-width: @screen-xl) {
  .panel .panel-heading .main-sub-text {
    font-size: 16px;
  }
}

.panel .panel-heading .secondary-sub-text {
  font-size: 14px;
}

.panel .panel-footer {
  border-radius: 0px 0px 7px 7px;
  border-top-color: @panelFooterBackgroundColor;
  background-color: @panelFooterBackgroundColor  !important;
}

.panel .panel-footer {
  font-size: 16px;
}

.panel .panel-footer .title {
  font-size: 18px;
  font-weight: 600;
}
.panel .panel-footer .fa {
  vertical-align: middle;
  line-height: normal;
}

.api-view .panel .panel-footer {
  color: @colorOnLight;
}

/* API Panel (hover #080A0C) */
.panel-api {
  border-color: @panelApiBorderColor;
  background-color: @panelApiBackgroundColor;
}

.panel-api .panel-heading {
  border-color: @panelApiBorderColor;
  background-color: @panelApiBackgroundColor;
}

.panel-api a {
  color: @panelApiFooterColor;
}

.panel-api a:hover {
  color: @panelApiHoverColor;
}
.panel-api a:hover>.panel-heading,
.panel-api a:active>.panel-heading {
  border-color: @panelApiHoverBackgroundColor;
  background-color: @panelApiHoverBackgroundColor;
}

/* Org Panel */
.panel-org {
  border-color: @panelOrgBorderColor;
  background-color: @panelOrgBackgroundColor;
}

.panel-org .panel-heading {
  border-color: @panelOrgBorderColor;
  background-color: @panelOrgBackgroundColor;
}

.panel-org a {
  color: @panelOrgFooterColor;
}

.panel-org a:hover {
  color: @panelOrgHoverColor;
}
.panel-org a:hover>.panel-heading,
.panel-org a:active>.panel-heading {
  border-color: @panelOrgHoverBackgroundColor;
  background-color: @panelOrgHoverBackgroundColor;
}

/* Group Panel */
.panel-group {
  border-color: @panelGroupBorderColor;
  background-color: @panelGroupBackgroundColor;
}

.panel-group .panel-heading {
  border-color: @panelGroupBorderColor;
  background-color: @panelGroupBackgroundColor;
}

.panel-group a {
  color: @panelGroupFooterColor;
}

.panel-group a:hover {
  color: @panelGroupHoverColor;
}
.panel-group a:hover>.panel-heading,
.panel-group a:active>.panel-heading {
  border-color: @panelGroupHoverBackgroundColor;
  background-color: @panelGroupHoverBackgroundColor;
}

/* Invite Panel */
.panel-invite {
  border-color: @panelInviteBorderColor;
  background-color: @panelInviteBackgroundColor;
}

.panel-invite .panel-heading {
  border-color: @panelInviteBorderColor;
  background-color: @panelInviteBackgroundColor;
}

.panel-invite a {
  color: @panelInviteFooterColor;
}

.panel-invite a:hover {
  color: @panelInviteHoverColor;
}
.panel-invite a:hover>.panel-heading,
.panel-invite a:active>.panel-heading {
  border-color: @panelInviteHoverBackgroundColor;
  background-color: @panelInviteHoverBackgroundColor;
}


/* Invite Forms Panel Email */
.panel-invite-form {
  border-width: @panelButtonInviteFormBorderWidth;
  border-style: solid;
  border-color: @panelButtonInviteFormBorderColor;
  background-color: @panelButtonInviteFormBackground;
}

.panel-invite-form .panel-heading {
  background-color: @panelButtonInviteFormBackground;
}

.panel-invite-form a:hover>.panel-heading,
.panel-invite-form a:active>.panel-heading {
  background-color: @panelButtonInviteFormHoverBackgroundColor;
}

.panel-invite-form a {
  color: @panelButtonInviteFormFooterColor;
  transition: color 0.4s;
}

.panel-invite-form a:hover {
  color: @panelButtonInviteFormHoverColor;
  text-decoration: none;
}

.panel-invite-inactive {
  position: absolute;
  top: 0px;
  left: 15px;
  right: 15px;
  bottom: 0px;
  background-color: black;
  opacity: 0.4;
  border-radius: 8px;
}

@media (min-width: 768px) {
  .panel-invite-inactive {
    bottom: 20px;
  }
}

/* Invite Forms Panel PDF */
.panel-invite-form-pdf {
  border-width: @panelButtonInviteFormPDFBorderWidth;
  border-style: solid;
  border-color: @panelButtonInviteFormPDFBorderColor;
  background-color: @panelButtonInviteFormPDFBackground;
}

.panel-invite-form-pdf .panel-heading {
  background-color: @panelButtonInviteFormPDFBackground;
}

.panel-invite-form-pdf a:hover>.panel-heading,
.panel-invite-form-pdf a:active>.panel-heading {
  background-color: @panelButtonInviteFormPDFHoverBackgroundColor;
}

.panel-invite-form-pdf a {
  color: @panelButtonInviteFormPDFFooterColor;
  transition: color 0.4s;
}

.panel-invite-form-pdf a:hover {
  color: @panelButtonInviteFormPDFHoverColor;
  text-decoration: none;
}

/* User Panel */
.panel-user {
  border-color: @panelUserBorderColor;
  background-color: @panelUserBackgroundColor;
}

.panel-user .panel-heading {
  border-color: @panelUserBorderColor;
  background-color: @panelUserBackgroundColor;
}

.panel-user a {
  color: @panelUserFooterColor;
}

.panel-user a:hover {
  color: @panelUserHoverColor;
}
.panel-user a:hover>.panel-heading,
.panel-user a:active>.panel-heading {
  border-color: @panelUserHoverBackgroundColor;
  background-color: @panelUserHoverBackgroundColor;
}

/* Tag Panel */
.panel-tag {
  border-color: @panelTagBorderColor;
  background-color: @panelTagBackgroundColor;
}

.panel-tag .panel-heading {
  border-color: @panelTagBorderColor;
  background-color: @panelTagBackgroundColor;
}

.panel-tag a {
  color: @panelTagFooterColor;
}

.panel-tag a:hover {
  color: @panelTagHoverColor;
}
.panel-tag a:hover>.panel-heading,
.panel-tag a:active>.panel-heading {
  border-color: @panelTagHoverBackgroundColor;
  background-color: @panelTagHoverBackgroundColor;
}

/* Completed Session Panel */
.panel-completed {
  border-color: @panelCompletedBorderColor;
  background-color: @panelCompletedBackgroundColor;
}

.panel-completed .panel-heading {
  border-color: @panelCompletedBorderColor;
  background-color: @panelCompletedBackgroundColor;
}

.panel-completed a {
  color: @panelCompletedFooterColor;
}

.panel-completed a:hover {
  color: @panelCompletedHoverColor;
}
.panel-completed a:hover>.panel-heading,
.panel-completed a:active>.panel-heading {
  border-color: @panelCompletedHoverBackgroundColor;
  background-color: @panelCompletedHoverBackgroundColor;
}

/* Inprogress Session Panel */
.panel-inprogress {
  border-color: @panelInprogressBorderColor;
  background-color: @panelInprogressBackgroundColor;
}

.panel-inprogress .panel-heading {
  border-color: @panelInprogressBorderColor;
  background-color: @panelInprogressBackgroundColor;
}

.panel-inprogress a {
  color: @panelInprogressFooterColor;
}

.panel-inprogress a:hover {
  color: @panelInprogressHoverColor;
}
.panel-inprogress a:hover>.panel-heading,
.panel-inprogress a:active>.panel-heading {
  border-color: @panelInprogressHoverBackgroundColor;
  background-color: @panelInprogressHoverBackgroundColor;
}

/* Knowledge Base Article Panel */
.panel-kb-article {
  border-color: @panelKbArticleBorderColor;
  background-color: @panelKbArticleBackgroundColor;
}

.panel-kb-article .panel-heading {
  border-color: @panelKbArticleBorderColor;
  background-color: @panelKbArticleBackgroundColor;
}

.panel-kb-article a {
  color: @panelKbArticleFooterColor;
}

.panel-kb-article a:hover {
  color: @panelKbArticleHoverColor;
}
.panel-kb-article a:hover>.panel-heading,
.panel-kb-article a:active>.panel-heading {
  border-color: @panelKbArticleHoverBackgroundColor;
  background-color: @panelKbArticleHoverBackgroundColor;
}

/* Knowledge Base Topic Panel */
.panel-kb-topic {
  border-color: @panelKbTopicBorderColor;
  background-color: @panelKbTopicBackgroundColor;
}

.panel-kb-topic .panel-heading {
  border-color: @panelKbTopicBorderColor;
  background-color: @panelKbTopicBackgroundColor;
}

.panel-kb-topic a {
  color: @panelKbTopicFooterColor;
}

.panel-kb-topic a:hover {
  color: @panelKbTopicHoverColor;
}
.panel-kb-topic a:hover>.panel-heading,
.panel-kb-topic a:active>.panel-heading {
  border-color: @panelKbTopicHoverBackgroundColor;
  background-color: @panelKbTopicHoverBackgroundColor;
}

/* Coverage Panel */
.panel-coverage {
  border-color: @panelCoverageBorderColor;
  background-color: @panelCoverageBackgroundColor;
}

.panel-coverage .panel-heading {
  border-color: @panelCoverageBorderColor;
  background-color: @panelCoverageBackgroundColor;
}

.panel-coverage a {
  color: @panelCoverageFooterColor;
}

.panel-coverage a:hover {
  color: @panelCoverageHoverColor;
}
.panel-coverage a:hover>.panel-heading,
.panel-coverage a:active>.panel-heading {
  border-color: @panelCoverageHoverBackgroundColor;
  background-color: @panelCoverageHoverBackgroundColor;
}

/* Parameters Panel */
.panel-param {
  border-color: @panelParamBorderColor;
  background-color: @panelParamBackgroundColor;
}

.panel-param .panel-heading {
  border-color: @panelParamBorderColor;
  background-color: @panelParamBackgroundColor;
}

.panel-param a {
  color: @panelParamFooterColor;
}

.panel-param a:hover {
  color: @panelParamHoverColor;
}
.panel-param a:hover>.panel-heading,
.panel-param a:active>.panel-heading {
  border-color: @panelParamHoverBackgroundColor;
  background-color: @panelParamHoverBackgroundColor;
}

/* Average Content Score Panel */
.panel-average {
  border-color: @panelAverageBorderColor;
  background-color: @panelAverageBackgroundColor;
}

.panel-average .panel-heading {
  border-color: @panelAverageBorderColor;
  background-color: @panelAverageBackgroundColor;
}

.panel-average a {
  color: @panelAverageFooterColor;
}

.panel-average a:hover {
  color: @panelAverageHoverColor;
}
.panel-average a:hover>.panel-heading,
.panel-average a:active>.panel-heading {
  border-color: @panelAverageHoverBackgroundColor;
  background-color: @panelAverageHoverBackgroundColor;
}

/* Time Usage Panel */
.panel-time-usage {
  border-color: @panelTimeUsageBorderColor;
  background-color: @panelTimeUsageBackgroundColor;
}

.panel-time-usage .panel-heading {
  border-color: @panelTimeUsageBorderColor;
  background-color: @panelTimeUsageBackgroundColor;
}

.panel-time-usage .panel-symbol {
  line-height: 1;
  font-weight: 600;
}

.panel-time-usage a {
  color: @panelTimeUsageFooterColor;
}

.panel-time-usage a:hover {
  color: @panelTimeUsageHoverColor;
}
.panel-time-usage a:hover>.panel-heading,
.panel-time-usage a:active>.panel-heading {
  border-color: @panelTimeUsageHoverBackgroundColor;
  background-color: @panelTimeUsageHoverBackgroundColor;
}

/* Usage Value Panel */
.panel-usage-value {
  border-color: @panelUsageValueBorderColor;
  background-color: @panelUsageValueBackgroundColor;
}

.panel-usage-value .panel-heading {
  border-color: @panelUsageValueBorderColor;
  background-color: @panelUsageValueBackgroundColor;
}

.panel-usage-value .panel-symbol {
  line-height: 1;
  font-weight: 600;
}

.panel-usage-value a {
  color: @panelUsageValueFooterColor;
}

.panel-usage-value a:hover {
  color: @panelUsageValueHoverColor;
}
.panel-usage-value a:hover>.panel-heading,
.panel-usage-value a:active>.panel-heading {
  border-color: @panelUsageValueHoverBackgroundColor;
  background-color: @panelUsageValueHoverBackgroundColor;
}

/* Usage Value Rate Panel */
.panel-usage-value-rate {
  border-color: @panelUsageValueRateBorderColor;
  background-color: @panelUsageValueRateBackgroundColor;
}

.panel-usage-value-rate .panel-heading {
  border-color: @panelUsageValueRateBorderColor;
  background-color: @panelUsageValueRateBackgroundColor;
}

.panel-usage-value-rate .panel-symbol {
  line-height: 1;
  font-weight: 600;
}

.panel-usage-value-rate a {
  color: @panelUsageValueRateFooterColor;
}

.panel-usage-value-rate a:hover {
  color: @panelUsageValueRateHoverColor;
}
.panel-usage-value-rate a:hover>.panel-heading,
.panel-usage-value-rate a:active>.panel-heading {
  border-color: @panelUsageValueRateHoverBackgroundColor;
  background-color: @panelUsageValueRateHoverBackgroundColor;
}

/* Usage Value Total Panel */
.panel-usage-value-total {
  border-color: @panelUsageValueTotalBorderColor;
  background-color: @panelUsageValueTotalBackgroundColor;
}

.panel-usage-value-total .panel-heading {
  border-color: @panelUsageValueTotalBorderColor;
  background-color: @panelUsageValueTotalBackgroundColor;
}

.panel-usage-value-total .panel-symbol {
  line-height: 1;
  font-weight: 600;
}

.panel-usage-value-total a {
  color: @panelUsageValueTotalFooterColor;
}

.panel-usage-value-total a:hover {
  color: @panelUsageValueTotalHoverColor;
}
.panel-usage-value-total a:hover>.panel-heading,
.panel-usage-value-total a:active>.panel-heading {
  border-color: @panelUsageValueTotalHoverBackgroundColor;
  background-color: @panelUsageValueTotalHoverBackgroundColor;
}

/* Log Panel */
.panel-log {
  border-color: @panelLogBorderColor;
  background-color: @panelLogBackgroundColor;
}

.panel-log .panel-heading {
  border-color: @panelLogBorderColor;
  background-color: @panelLogBackgroundColor;
}

.panel-log a {
  color: @panelLogFooterColor;
}

.panel-log a:hover {
  color: @panelLogHoverColor;
}
.panel-log a:hover>.panel-heading,
.panel-log a:active>.panel-heading {
  border-color: @panelLogHoverBackgroundColor;
  background-color: @panelLogHoverBackgroundColor;
}

/* Memo Panel */
.panel-memo {
  border-color: @panelMemoBorderColor;
  background-color: @panelMemoBackgroundColor;
}

.panel-memo .panel-heading {
  border-color: @panelMemoBorderColor;
  background-color: @panelMemoBackgroundColor;
}

.panel-memo a {
  color: @panelMemoFooterColor;
}

.panel-memo a:hover {
  color: @panelMemoHoverColor;
}
.panel-memo a:hover>.panel-heading,
.panel-memo a:active>.panel-heading {
  border-color: @panelMemoHoverBackgroundColor;
  background-color: @panelMemoHoverBackgroundColor;
}

/* Author Panel */
.panel-author {
  border-color: @panelAuthorBorderColor;
  background-color: @panelAuthorBackgroundColor;
}

.panel-author .panel-heading {
  border-color: @panelAuthorBorderColor;
  background-color: @panelAuthorBackgroundColor;
}

.panel-author a {
  color: @panelAuthorFooterColor;
}

.panel-author a:hover {
  color: @panelAuthorHoverColor;
}
.panel-author a:hover>.panel-heading,
.panel-author a:active>.panel-heading {
  border-color: @panelAuthorHoverBackgroundColor;
  background-color: @panelAuthorHoverBackgroundColor;
}

/* Tag Panel */
.panel-tag {
  border-color: @panelTagBorderColor;
  background-color: @panelTagBackgroundColor;
}

.panel-tag .panel-heading {
  border-color: @panelTagBorderColor;
  background-color: @panelTagBackgroundColor;
}

.panel-tag a {
  color: @panelTagFooterColor;
}

.panel-tag a:hover {
  color: @panelTagHoverColor;
}
.panel-tag a:hover>.panel-heading,
.panel-tag a:active>.panel-heading {
  border-color: @panelTagHoverBackgroundColor;
  background-color: @panelTagHoverBackgroundColor;
}

/* Content-priority-recurrent Panel */
.panel-content-priority-recurrent {
  border-color: @panelContentPriorityBorderColor;
  background-color: @panelContentPriorityBackgroundColor;
}

.panel-content-priority-recurrent .panel-heading {
  border-color: @panelContentPriorityBorderColor;
  background-color: @panelContentPriorityBackgroundColor;
}

.panel-content-priority-recurrent a {
  color: @panelContentPriorityFooterColor;
}

.panel-content-priority-recurrent a:hover {
  color: @panelContentPriorityHoverColor;
}
.panel-content-priority-recurrent a:hover>.panel-heading,
.panel-content-priority-recurrent a:active>.panel-heading {
  border-color: @panelContentPriorityHoverBackgroundColor;
  background-color: @panelContentPriorityHoverBackgroundColor;
}

/* Content Panel */
.panel-content {
  border-color: @panelContentBorderColor;
  background-color: @panelContentBackgroundColor;
}

.panel-content .panel-heading {
  border-color: @panelContentBorderColor;
  background-color: @panelContentBackgroundColor;
}

.panel-content a {
  color: @panelContentFooterColor;
}

.panel-content a:hover {
  color: @panelContentHoverColor;
}
.panel-content a:hover>.panel-heading,
.panel-content a:active>.panel-heading {
  border-color: @panelContentHoverBackgroundColor;
  background-color: @panelContentHoverBackgroundColor;
}

/* Library Panel */
.panel-library {
  border-color: @panelLibraryBorderColor;
  background-color: @panelLibraryBackgroundColor;
}

.panel-library .panel-heading {
  border-color: @panelLibraryBorderColor;
  background-color: @panelLibraryBackgroundColor;
}

.panel-library a {
  color: @panelLibraryFooterColor;
}

.panel-library a:hover {
  color: @panelLibraryHoverColor;
}
.panel-library a:hover>.panel-heading,
.panel-library a:active>.panel-heading {
  border-color: @panelLibraryHoverBackgroundColor;
  background-color: @panelLibraryHoverBackgroundColor;
}

/* Category Panel */
.panel-category {
  border-color: @panelCategoryBorderColor;
  background-color: @panelCategoryBackgroundColor;
}

.panel-category .panel-heading {
  border-color: @panelCategoryBorderColor;
  background-color: @panelCategoryBackgroundColor;
}

.panel-category a {
  color: @panelCategoryFooterColor;
}

.panel-category a:hover {
  color: @panelCategoryHoverColor;
}
.panel-category a:hover>.panel-heading,
.panel-category a:active>.panel-heading {
  border-color: @panelCategoryHoverBackgroundColor;
  background-color: @panelCategoryHoverBackgroundColor;
}