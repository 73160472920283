.nav-tabs {
  font-size: 18px;
  cursor: pointer;
  border-bottom: @navtabs-border-bottom;
}
.nav-tabs > li > a {
    color: @color;
    font-weight: 500;
}

.nav-tabs > li > a:hover {
  color: @navtabs-hover-color;
  background-color: @navtabs-hover-background-color;
  border-bottom: @navtabs-border-bottom;
}

.nav-tabs>li.active>a {
  color: @navtabs-hover-color;
  background-color: @navtabs-active-background-color;
  border-width: 1px;
  border-style: solid;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom: @navtabs-border-bottom;
  outline: none;
}

.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
  color: @navtabs-hover-color;
  background-color: @navtabs-hover-background-color;
  border-width: 1px;
  border-style: solid;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom: @navtabs-border-bottom;
  outline: none;
}

.nav-tabs > li > a:hover {
  color: @navtabs-hover-color;
  background-color: @navtabs-hover-background-color;
  border-bottom: @navtabs-border-bottom;
}

.nav-tabs > li > a:focus {
  background-color: @navtabs-hover-background-color;
  border-color: @navtabs-hover-background-color;
  border-bottom: @navtabs-border-bottom;
}

.nav-tabs > li > a {
  padding: 10px 10px;
}

@media (min-width: 992px) {
  .nav-tabs > li > a {
    padding: 10px 15px;
  }
}