/* Grid memo item */

.grid-memo-item {
  position: absolute;
  width:100%;
  color: @gridMemoColor;
  border-width: 2px;
  border-color: @colorMuted;
  margin-bottom: 15px;
}

.grid-memo-item-detail {
  position: relative;
}

.grid-memo-item-content {
  padding: 10px;
  background-color: @gridMemoBackground;
  box-shadow: 0px 2px 4px 0px @grid-item-shadow;
  border-radius: 8px;
}

.grid-memo-item-action:hover {
  box-shadow: 0px 0px 2px 3px @gridItemHoverColor;
}

.grid-memo-item-thumbnail {
  padding-left: 10px;
  padding-right: 5px;
  display: table;
}

@media (max-width: 991px) {
  .grid-memo-item-thumbnail {
    padding-right: 10px;
  }
}

.grid-memo-item-read-status {
  padding-left: 5px;
  padding-right: 5px;
  width: 12px;
  height: 125px;
  display: table-cell;
  vertical-align: middle;
}

.text-not-read-status {
  color: @grid-text-not-read-status-color;  
}

.text-read-status {
  color: transparent;  
}

.grid-memo-item-thumbnail img {
  max-width: 117px;
  max-height: 135px;
  display: table-cell;
  vertical-align: middle;
}

.grid-memo-item .grid-memo-item-title {
   margin-top: 0;
   margin-bottom: 0;
}

.grid-memo-item .grid-memo-item-subtitle {
   margin-top: 0;
   margin-bottom: 2px;
}

.grid-memo-item .grid-memo-item-divider {
  background: @borderColor;
  height:1px;
  border: 0;
  margin-bottom: 8px;
}

.grid-memo-item .grid-memo-item-heading {
  font-size: 16px;
  color: @color;
  line-height: 18px;
  text-align: left;
  margin-bottom: 8px;
}

.grid-memo-item .grid-memo-item-tags > .label {
  margin-right: 10px;
}

.grid-memo-item-text {
  padding-left: 5px;
  margin-right: -5px;
}

@media (max-width: 991px) {
  .grid-memo-item-text {
    padding-left: 15px;
  }
}

.grid-memo .grid-memo-item-text {
    padding-right: 22px;
}

.grid-memo .grid-memo-item-title {
    padding-right: 30px;
}

.grid-memo-item .grid-memo-item-body {
  border: none;
  background-color: transparent;
}

@media (min-width: 768px) {
  .grid-memo-item .grid-memo-item-body {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
  }
}

@media (min-width: 992px) {
  .grid-memo-item .grid-memo-item-body {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 0px;
  }
}

.grid-memo-item .grid-memo-item-label {
  display: inline-block;
  padding-right: 5px;
  //padding-bottom: 5px;
}

.grid-memo-item .close {
  border-radius: 6px;
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
  opacity: 1;
  text-shadow: 0 1px 0 @modal-close-shadow-color;
}
.grid-memo-item .close:before {
  border-radius: 6px;
  position: absolute;
  content: "\00D7";
  font-size: 36px;
  font-weight: normal;
  line-height: 36px;
  height: 36px;
  width: 36px;
  text-align: center;
  top: 0px;
  right: 0px;
  color: @vex-close-color;
  background: @vex-close-background; 
}
.grid-memo-item .close:hover:before, 
.grid-memo-item .close:active:before {
  color: @vex-close-hover-color;
  background: @vex-close-hover-background;
  
}