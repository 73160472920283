.vex-content-iframe {
  border: 0;
  padding: 0;
}

.vex-content-iframe-fullpage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin-top: 45px;
  border-radius: 8px;
}

.vex-content-status {
  margin-top: 50px;
}

.vex-content-status-filler {
  min-height: 500px;
}

.vex-content-iframe-status {
  position: absolute;
  top: 0;
  left: 0;
  height: 75%;
  width: 100%;
  border-radius: 8px 8px 0 0;
  background-color: @vexBackgroundLight;
  overflow: hidden;
}

.vex-content-status-source,
.vex-content-status-source a {
  color: @colorMuted;
  background-color: @vexBackgroundLight;
  height: 30px;
  line-height: 30px;
}

.vex-content-status-source h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.vex-fullpage {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.vex-content-fullpage {
  height: auto;
  width: 100% !important;
  min-height: 95%;
}

.vex.vex-theme-mosaik .vex-content-fullpage {
  height: auto;
  width: 100%;
  min-height: 95%;
}

.vex.vex-theme-mosaik .vex-content-fullpage .vex-close:before {
  right: 15px;
}

@media (min-width: @screen-sm) {
  .vex-fullpage {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .vex.vex-theme-mosaik .vex-content-fullpage {
    width: 90% !important;
    margin: 0 auto;
  }
}

.vex-content-status-others-container {
  position: absolute;
  top: 74%;
  left: 0;
  width: 100%;
  margin: 0 auto;
}

.vex-content-status-others-title {
  padding-top: 10px;
}

.btn-status-other,
.btn-status-other:active,
.btn-status-other:focus,
.btn-status-other:hover {
  color: @color;
  background: none;
  border-color: #ccc;
}