html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: @font-family-sans-serif !important;
  background-color: lighten(@background, 3%);
  background-image: linear-gradient(to bottom, @background 100%, lighten(@background, 3%) 100%);
  color: @color;
  min-height: 615px;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
  font-weight: 500;
}

h5,.h5 {
  font-size: 15px;
}

h6,.h6 {
  font-size: 13px;
}