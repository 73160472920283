.swal2-popup { //todo: remove with bootstrap 4. It's a fix for Bootstrap 3 compatibility. Otherwise, the popup is too small.
  font-size: 1.6rem !important;
  width: 38em !important;
  background-color: @swal2-background !important;
  font-family: "Source Sans 3", Helvetica, Arial, sans-serif !important;
  color: @color !important;
}

.swal2-popup-large {
  width: 48em !important;
}

.swal2-popup-command {
  width: 64em !important;
}

.swal2-html-container-monospace {
  font-family: monospace;
}

.swal2-icon::before {
  height: 100% !important;
}

.swal2-title {
  color: @swal2-title-color;
}

.swal2-content {
  color: @swal2-content-color;
}

.swal2-styled.swal2-confirm {
  background-color: @form-confirm-background-color !important;
  border-color: @form-confirm-border-color !important;
  font-size: 1.0625em;
  font-weight: 500;
}
.swal2-styled.swal2-confirm:hover {
  background-color: darken(@form-confirm-background-color, 10%) !important;
  border-color: darken(@form-confirm-border-color, 12%) !important;
}

.swal2-styled.swal2-cancel {
  background-color: @form-cancel-background-color;
  border-color: @form-cancel-border-color;
  font-size: 1.0625em;
}
.swal2-styled.swal2-cancel:hover {  
  background-color: darken(@form-cancel-background-color, 10%);
  border-color: darken(@form-cancel-border-color, 12%);
}

.swal2-styled {
  border-color: transparent;
  color: #FFFFFF;
  margin: 0.3125em;
  padding: 0.625em 2em;
}

.swal2-styled:hover {
  border-color: transparent;
  color: #FFFFFF;
}

.swal2-styled:focus {
  border-color: transparent;
  color: #FFFFFF;
}

.swal2-close:focus { 
  outline: none;
  box-shadow: none;
} 

div:where(.swal2-container) button:where(.swal2-styled) {
  transition: box-shadow 0.4s;
  box-shadow: 0 0 0 3px transparent;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus,
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:hover {
  box-shadow: 0 0 0 3px fade(@form-confirm-background-color, 50%);
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel:focus,
div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel:hover {
  box-shadow: 0 0 0 3px fade(@form-cancel-background-color, 50%);
}

// input with light BG
.swal2-modal .swal2-file,
.swal2-modal .swal2-input, 
.swal2-modal .swal2-textarea,
.swal2-modal .swal2-select {
  color: @InputColor !important;
  background: @InputbackgroundColor !important;
}

// input with default BG
.swal2-modal .swal2-radio {
  background-color: transparent !important;
}



.swal2-popup-full-width {
  width: 100% !important;
  height: 100% !important;
}

.swal2-content-full-width {
  z-index: auto;
  flex: 1;
}

.swal2-popup-full-width > .swal2-header > .swal2-progress-steps {
  font-size: 2.2rem !important;
  margin: 0 0 0.45em 0;
}

.swal2-popup-full-width > .swal2-header > .swal2-title {
  font-size: 1.75em;
  margin: 0;
}

.swal2-popup-full-width > .swal2-actions {
  flex: 0;
}

.swal2-popup-full-width > .swal2-header > .swal2-icon {
  width: 4em;
  height: 4em;
  line-height: 4em;
  margin: 1em auto 1em;
}

.swal2-popup-full-width > .swal2-header > .swal2-icon > .swal2-icon-content {
  font-size: 2.75em;
}

.swal-content-custom {
  width: 100%;
  margin: auto;
}
.swal2-actions > button.fill-width {
  width: inherit;
}
@media (min-width: @screen-sm) {
  .swal-content-custom {
    width:80%;
  }
}
@media (min-width: @screen-md) {
  .swal-content-custom {
    width:50%;
  }
  .swal2-actions > button.fill-width {
    width: 85%;
  }
}

.swal2-button-fix-width {
  width: 320px;
}