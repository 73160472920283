.meter-container > span {
  color: @colorMutedLight;
}

meter {
  /* Reset the default appearance */
  margin: 0 auto;
  width: 78%;
  height: .9em;

  // for IE11
  font-weight: normal;
  color: @colorMutedLight;

  /* Applicable only to Firefox */
  background: none;
}

meter::-webkit-meter-bar {
  background: none;
}

meter[value="0"]::-webkit-meter-optimum-value,
meter[value="1"]::-webkit-meter-optimum-value {
  background: @label-danger-color;
}

meter[value="2"]::-webkit-meter-optimum-value {
  background: @label-warning-color;
}

meter[value="3"]::-webkit-meter-optimum-value {
  background: @label-warning2-color;
}

meter[value="4"]::-webkit-meter-optimum-value {
  background: @label-success-color;
}

meter[value="1"]::-moz-meter-bar,
meter[value="1"]::-moz-meter-bar {
  background: @label-danger-color;
}

meter[value="2"]::-moz-meter-bar {
  background: @label-warning-color;
}

meter[value="3"]::-moz-meter-bar {
  background: @label-warning2-color;
}

meter[value="4"]::-moz-meter-bar {
  background: @label-success-color;
}

meter::-webkit-meter-optimum-value {
  transition: width .4s ease-out;
}