.form-invitation {
  max-width: 700px;
}

.form-invitation .form-invitation-center {
  margin: auto;
  width: 460px;
  padding-bottom:15px;
}

.form-invitation .flex-center-items {
  display: flex;
  align-items: center;
}

.form-invitation input[type="number"] {
  height: 36px;
  padding: 5px 5px 5px 10px;
}