@screen-xs: 480px;
@screen-xs-max: 479px;
@screen-xs-sm: 725px;
@screen-sm: 768px;
@screen-sm-max: 767px;
@screen-md: 992px;
@screen-md-max: 991px;
@screen-lg: 1200px;
@screen-lg-max: 1199px;
@screen-xl: 1440px;
@screen-xl-max: 1439px;


/* container (grid) */


// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
  @media (min-width: @screen-xl) {
    width: (@screen-xl - 60);
  }
}


/* Divider */
.divider {
  background: @dividerColor;
  height:2px;
  border: 0;
}

.divider-bottom {
  margin-top: 30px;
}

.text-highlight {
  color: @colorHighlight;
}

.text-muted {
  color: @colorMuted;
}

.text-hint {
  color: @colorMutedLight;
}

.table .text-muted {
  color: @colorMutedInTable;
}

/* Bootstrap addon */
.col-centered{
  float: none;
  margin: 0 auto;
}

/* Spacer */
.spacer {
  height: 15px;
}

.spacer-5px {
  height: 5px;
}

.spacer-10px {
  height: 10px;
}

.spacer-15px-bottom {
  padding-bottom: 15px;
}

.padding-lr-15 {
  padding-left: 15px;
  padding-right: 15px;
}

/* Bootstrap-select useful */
.padding-top-10 {
  padding-top: 10px;
}

/* Progress bar */
.progress {
  background-color: @progressContainerBackgroundColor;
  margin-bottom: 0px;
}

.progress .progress-bar {
  min-width: 2em;
  background-color: @progressBarBackgroundColor;
  color: @progressBarColor;
}

/* List-group */
/*.list-group-invites {
}*/

.list-group-item {
  background-color: inherit !important;
}

.list-group-item .remove {
  float: right;
  font-size: 20px;
  opacity: 0.6;
  line-height: 1;
}
.list-group-item .remove:hover {
  opacity: 0.8;
  cursor: pointer;
}

/* Column adjustment with viewport because they get one col onto the next without padding
   and other adjustment
*/
@media (max-width: 767px) {
  .col-xs-pad-top {
    padding-top: 15px;
  }
}

@media (min-width: @screen-sm) {
  .col-sm-pad-right {
    padding-right: 42px;
  }
}

@media (min-width: @screen-md) {
  .col-md-pad-right,
  .col-lg-pad-right,
  .col-xl-pad-right {
    padding-right: 20px;
  }
}

.text-xs-left { text-align: left; }
.text-xs-right { text-align: right; }
.text-xs-center { text-align: center; }
.text-xs-justify { text-align: justify; }

@media (min-width: @screen-sm) {
    .text-sm-left { text-align: left; }
    .text-sm-right { text-align: right; }
    .text-sm-center { text-align: center; }
    .text-sm-justify { text-align: justify; }
}

@media (min-width: @screen-md) {
    .text-md-left { text-align: left; }
    .text-md-right { text-align: right; }
    .text-md-center { text-align: center; }
    .text-md-justify { text-align: justify; }
}

@media (min-width: @screen-lg) {
    .text-lg-left { text-align: left; }
    .text-lg-right { text-align: right; }
    .text-lg-center { text-align: center; }
    .text-lg-justify { text-align: justify; }
}

@media (min-width: @screen-xl) {
  .text-xl-left { text-align: left; }
  .text-xl-right { text-align: right; }
  .text-xl-center { text-align: center; }
  .text-xl-justify { text-align: justify; }

  // Used xl columns (to completed as needed)
  .col-xl-offset-1 { margin-left: 8.33333333%; }
  .col-xl-offset-2 { margin-left: 16.66666666%; }
  .col-xl-offset-3 { margin-left: 25%; }
  .col-xl-offset-4 { margin-left: 33.33333333%; }
  .col-xl-offset-5 { margin-left: 41.66666666%; }
  .col-xl-offset-6 { margin-left: 50%; }
  .col-xl-offset-7 { margin-left: 58.33333333%; }
  .col-xl-offset-8 { margin-left: 66.66666666%; }
  .col-xl-offset-9 { margin-left: 75%; }
  .col-xl-offset-10 { margin-left: 83.33333333%; }
  .col-xl-offset-11 { margin-left: 91.66666666%; }
  .col-xl-1 { width: 8.33333333%; }
  .col-xl-2 { width: 16.66666666%; }
  .col-xl-3 { width: 25%; }
  .col-xl-4 { width: 33.33333333%; }
  .col-xl-5 { width: 41.66666666%; }
  .col-xl-6 { width: 50%; }
  .col-xl-7 { width: 58.33333333%; }
  .col-xl-8 { width: 66.66666666%; }
  .col-xl-9 { width: 75%; }
  .col-xl-10 { width: 83.33333333%; }
  .col-xl-11 { width: 91.66666666%; }
  .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9 {
    float: left;
  }
}

hr {
  border-color: @colorMuted;
}

hr.hr-slim {
  margin-top: 3px;
  margin-bottom: 3px;
}

.tooltip {
  font-family: @font-family-sans-serif !important;
}

.popover {
  font-family: @font-family-sans-serif !important;
}

.tooltip {
  font-size: 1em;
}

code {
  background-color: transparent;
}

kbd {
  color: @color;
  background-color: @backgroundAlt;
  font-size: 86%;
}