.form-view {
  padding: 15px 15px 400px 15px;
  margin: 0 auto;
}
@media (min-width: @screen-sm) {
  .form-view {
    padding-bottom: 260px;
  }
}
@media (min-width: @screen-md) {
  .form-view {
    padding-bottom: 220px;
  }
}
@media (min-width: @screen-lg) {
  .form-view {
    padding-bottom: 180px;
  }
}

.form-view .checkbox {
  margin-bottom: 10px;
}
.form-view .checkbox {
  font-weight: normal;
}
.form-view .form-control {
  position: relative;
  height: 46px;
  box-sizing: border-box;
  padding: 10px;
}

.form-view .input-group-btn>button {
  height: 46px;
}

.form-view .form-social {
  padding-bottom: 15px;
}

.form-view .form-default-center {
  margin: auto;
  width: 100%;
  padding-bottom: 20px;
}
.form-login .form-default-center {
  padding-bottom: 20px;
}
@media (min-width: @screen-xs) {
  .form-view .form-default-center {
    max-width: 304px;
  }
}
@media (min-width: @screen-sm) {
  .form-view .form-default-center {
    max-width: 380px;
  }
}

.form-view form h5 {
  margin-bottom: 5px;
}

.form-view .form-control:focus {
  z-index: 2;
}
.form-view input[type="email"] {
  margin-bottom: -1px;
}

.form-view .flex-center-items {
  display: flex;
  align-items: center;
}

.input-with-icon-group>input {
  padding-right: 26px;
}

.input-with-icon-group>input::-ms-clear {
  display: none;
}

.input-with-icon-group>span.input-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 7px;
  padding-right: 24px;
  color: @colorMuted;
}

.input-with-icon-group .form-control-clear {
    z-index: 10;
    pointer-events: auto;
    cursor: pointer;
    color: @colorMuted;
    padding-right: 38px;
  }