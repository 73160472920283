/* Grid kb item */

.grid-kb-item {
  position: absolute;
  width:100%;
  color: @gridKbColor;
  border-width: 2px;
  border-color: @colorMuted;
  margin-bottom: 15px;
}

.grid-kb-item-content {
  height: 90px;
  padding: 10px;
  background-color: @gridKbBackground;
  box-shadow: 0px 2px 4px 0px @grid-item-shadow;
  border-radius: 8px;
}

.grid-kb-item-action:hover {
  box-shadow: 0px 0px 2px 3px @gridItemHoverColor;
}

.grid-kb-item-icon {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.grid-kb-item-icon .fa {
  font-size: 4em;
  width: 1.1em;
  text-align: center;
}

.grid-kb-item-title-container {
  padding-left: 55px;
  padding-right: 15px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.grid-kb-item-title {
   margin-top: 0;
   margin-bottom: 0;
}

.grid-kb-item-title {
  padding-right: 0;
  font-size: 18px;
}
@media (min-width: @screen-xs) {
  .grid-kb-item-title-container {
    padding-left: 40px;
  }
}
@media (min-width: @screen-sm) {
  .grid-kb-item-title-container {
    padding-left: 30px;
  }
  .grid-kb-item-title {
    font-size: 24px;
  }
}
@media (min-width: @screen-md) {
  .grid-kb-item-title-container {
    padding-left: 10px;
    padding-right: 25px;
  }
  .grid-kb-item-title {
    font-size: 26px;
  }
}
@media (min-width: @screen-lg) {
  .grid-kb-item-title-container {
    padding-left: 0;
  }
  .grid-kb-item-title {
    font-size: 26px;
  }
}

.grid-kb-item .close {
  border-radius: 6px;
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
  opacity: 1;
}
.grid-kb-item .close:before {
  border-radius: 6px;
  position: absolute;
  content: "\00D7";
  font-size: 36px;
  font-weight: normal;
  line-height: 36px;
  height: 36px;
  width: 36px;
  text-align: center;
  top: 0px;
  right: 0px;
  color: @vex-close-color;
  background: @vex-close-background; 
}
.grid-kb-item .close:hover:before, 
.grid-kb-item .close:active:before {
  color: @vex-close-hover-color;
  background: @vex-close-hover-background; 
}