@keyframes vex-flyin {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes vex-flyin {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes vex-flyin {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-ms-keyframes vex-flyin {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-o-keyframes vex-flyin {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes vex-flyout {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}

@-webkit-keyframes vex-flyout {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}

@-moz-keyframes vex-flyout {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}

@-ms-keyframes vex-flyout {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}

@-o-keyframes vex-flyout {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}

@keyframes vex-pulse {
  0% {
    box-shadow: inset 0 0 0 300px transparent;
  }

  70% {
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }

  100% {
    box-shadow: inset 0 0 0 300px transparent;
  }
}

@-webkit-keyframes vex-pulse {
  0% {
    box-shadow: inset 0 0 0 300px transparent;
  }

  70% {
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }

  100% {
    box-shadow: inset 0 0 0 300px transparent;
  }
}

@-moz-keyframes vex-pulse {
  0% {
    box-shadow: inset 0 0 0 300px transparent;
  }

  70% {
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }

  100% {
    box-shadow: inset 0 0 0 300px transparent;
  }
}

@-ms-keyframes vex-pulse {
  0% {
    box-shadow: inset 0 0 0 300px transparent;
  }

  70% {
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }

  100% {
    box-shadow: inset 0 0 0 300px transparent;
  }
}

@-o-keyframes vex-pulse {
  0% {
    box-shadow: inset 0 0 0 300px transparent;
  }

  70% {
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }

  100% {
    box-shadow: inset 0 0 0 300px transparent;
  }
}

.vex.vex-theme-mosaik {
  padding-top: 15px;
  padding-bottom: 60px;
  z-index: 1050;
}

.vex-overlay {
  z-index: 1050;
}

@media (min-width: 370px) {
  .vex.vex-theme-mosaik {
    padding-top: 60px;
  }
}

.vex.vex-theme-mosaik.vex-closing .vex-content {
  animation: vex-flyout 0.5s;
}

.vex.vex-theme-mosaik .vex-content {
  animation: vex-flyin 0.5s;
}

.vex.vex-theme-mosaik .vex-content {
  font-family: "Source Sans 3", Helvetica, Arial, sans-serif;
  background: @vexBackground;
  color: @color;
  padding: 15px;
  position: relative;
  margin: 0 auto;
  min-width: 330px;
  width: 60%;
  font-size: inherit;
  line-height: inherit;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.50);
  border-radius: 8px;
}

.vex.vex-theme-mosaik .vex-content h1,
.vex.vex-theme-mosaik .vex-content h2,
.vex.vex-theme-mosaik .vex-content h3,
.vex.vex-theme-mosaik .vex-content h4,
.vex.vex-theme-mosaik .vex-content h5,
.vex.vex-theme-mosaik .vex-content h6,
.vex.vex-theme-mosaik .vex-content p,
.vex.vex-theme-mosaik .vex-content ul,
.vex.vex-theme-mosaik .vex-content li {
  color: inherit;
}

.vex.vex-theme-mosaik .vex-content h1,
.vex.vex-theme-mosaik .vex-content h2,
.vex.vex-theme-mosaik .vex-content h3,
.vex.vex-theme-mosaik .vex-content h4,
.vex.vex-theme-mosaik .vex-content h5,
.vex.vex-theme-mosaik .vex-content h6 {
  margin-top: 0px;
}

.vex.vex-theme-mosaik .vex-content.vex-mosaik-content {
  width: 320px;
  min-width: 320px;
  padding-top: 40px;
  margin: 0;
}

@media (min-width: 370px) {
  .vex.vex-theme-mosaik .vex-content.vex-mosaik-content {
    width: 368px;
    padding-top: 40px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .vex.vex-theme-mosaik .vex-content.vex-mosaik-content {
    max-width: none;
    width: 748px;
    padding: 0px;
  }
}

@media (min-width: 992px) {
  .vex.vex-theme-mosaik .vex-content.vex-mosaik-content {
    width: 840px;
    padding: 0px;
  }
}

.vex.vex-theme-mosaik .vex-print {
  top: 7px;
  right: 54px;
  cursor: pointer;
  z-index: 1001;
  border-radius: 6px;
  position: absolute;
  width: 30px;
  height: 30px;
  font-size: 24px;
  text-align: center;
}

.vex.vex-theme-mosaik .vex-print:hover,
.vex.vex-theme-mosaik .vex-print:active {
  color: @hoverColor;
  background: @vex-close-hover-background;
}

.vex.vex-theme-mosaik .vex-close {
  border-radius: 6px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 1001;
}

.vex.vex-theme-mosaik .vex-close:before {
  border-radius: 6px;
  position: absolute;
  content: "\00D7";
  font-size: 36px;
  font-weight: normal;
  line-height: 36px;
  height: 30px;
  width: 30px;
  text-align: center;
  top: 5px;
  right: 5px;
  color: @vex-close-color;
  background: @vex-close-background;
}

.vex.vex-theme-mosaik.vex-light .vex-close:before {
  color: @colorOnLight;
}

.vex.vex-theme-mosaik .vex-close:hover:before,
.vex.vex-theme-mosaik .vex-close:active:before {
  color: @vex-close-hover-color;
  background: @vex-close-hover-background;
}

.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-message {
  margin-bottom: 0.5em;
}

.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input {
  margin-bottom: 1em;
}

.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input textarea,
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="date"],
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="datetime"],
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="datetime-local"],
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="email"],
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="month"],
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="number"],
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="password"],
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="search"],
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="tel"],
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="text"],
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="time"],
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="url"],
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="week"] {
  border-radius: 6px;
  background: @colorOnDark;
  width: 100%;
  padding: 0.25em 0.67em;
  border: 0;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  min-height: 2.5em;
  margin: 0 0 0.25em;
}

.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input textarea:focus,
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="date"]:focus,
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="datetime"]:focus,
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="datetime-local"]:focus,
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="email"]:focus,
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="month"]:focus,
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="number"]:focus,
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="password"]:focus,
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="search"]:focus,
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="tel"]:focus,
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="text"]:focus,
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="time"]:focus,
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="url"]:focus,
.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-input input[type="week"]:focus {
  box-shadow: inset 0 0 0 2px #8dbdf1;
  outline: none;
}

.vex.vex-theme-mosaik .vex-dialog-form .vex-dialog-buttons:after {
  content: "";
  display: table;
  clear: both;
}

.vex.vex-theme-mosaik .vex-dialog-button {
  border-radius: 6px;
  border: 0;
  float: right;
  margin: 0 0 0 0.5em;
  font-family: inherit;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 0.8em;
  line-height: 1em;
  padding: 0.75em 2em;
}

.vex.vex-theme-mosaik .vex-dialog-button.vex-last {
  margin-left: 0;
}

.vex.vex-theme-mosaik .vex-dialog-button:focus {
  animation: vex-pulse 1.1s infinite;
  outline: none;
}

@media (max-width: 568px) {
  .vex.vex-theme-mosaik .vex-dialog-button:focus {
    animation: none;
  }
}

.vex.vex-theme-mosaik .vex-dialog-button.vex-dialog-button-primary {
  background: #3288e6;
  color: @colorOnDark;
}

.vex.vex-theme-mosaik .vex-dialog-button.vex-dialog-button-secondary {
  background: #e0e0e0;
  color: #777;
}

.vex-loading-spinner.vex-theme-mosaik {
  box-shadow: 0 0 0 0.5em #f0f0f0, 0 0 1px 0.5em rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  background: #f0f0f0;
  border: 0.2em solid transparent;
  border-top-color: #bbb;
  top: -1.1em;
  bottom: auto;
}