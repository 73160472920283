.dropdown-menu .inner.open {
  padding-bottom: 5px;
}

.dropdown-menu > li > a {
  color: @color;
  cursor: pointer;
  font-size: 16px;
}

.dropdown-menu > li > a:hover {
  background-color: @navbar-dropdown-menu-background;
}

.dropdown-menu > li > a:focus {
  color: #FFFFFF !important;
  background-color: @navbar-dropdown-menu-background;
}

.dropdown-menu > li.divider {
  background-color: @dividerColor;
}

// Dropdown for bootstrap-select lists and datatime picker
.bootstrap-select > .dropdown-menu,
.bootstrap-select > .dropdown-menu > ul,
.bootstrap-select > .dropdown-menu > li > .divider,
.daterangepicker.dropdown-menu {
  background-color: #FFFFFF;
}
.bootstrap-select .dropdown-menu > li > a {
  color: @colorOnLight;
  font-size: 16px;
}
.bootstrap-select.with-label .dropdown-menu > li > a {
  line-height: 22px;
}
.bootstrap-select .dropdown-menu > li > a:hover {
  background-color: @hoverColorDropdown;
}

.bootstrap-select .dropdown-menu > li > a:focus {
  color: #FFFFFF;
  background-color: @activeColor;
}

.bootstrap-select .dropdown-menu > li.active > a {
  color: #FFFFFF;
  background-color: @activeColor;
}

.dt-buttons > .bootstrap-select .dropdown-menu > li > a:hover,
.dt-buttons > .bootstrap-select .dropdown-menu > li > a:focus,
.dt-buttons > .bootstrap-select .dropdown-menu > li.active > a {
  background-color: @table-button-selected-background-color;
}