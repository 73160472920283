/* Grid */
@media (max-width: @screen-xs-sm) {
  .grid {
    margin: 0 auto;
  }
}

.grid-wrapper-center {
  margin: 0 auto;
  position: relative;
  padding-bottom: 30px;
  padding-left: 15px;
}

/* Clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}