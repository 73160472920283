pre.json-pretty {
  background-color: @background;
  border: 1px solid @background;
  padding: 5px;
  margin: none;
  color: @colorMuted;
  font-size: 0.8em;
  min-width: 320px;
}

@media (min-width: @screen-xs) {
  pre.json-pretty {
    min-width: 400px;
  }
}

.json-key {
  color: @colorDanger;
}

.json-value {
  color: @label-info-color;
}

.json-string {
  color: @label-success-color;
}