/* Intl telephone input */
.iti {
  color: @intl-tel-input-country-color;
  width: 100%;
  text-align: left;
}
@media (min-width: @screen-sm) {
  .iti {
    width: 380px;
  }
}

.iti .iti__highlight {
  color: @intl-tel-input-country-color;
  background-color: @hoverColorDropdown;
}

.iti .iti__dial-code {
  color: @intl-tel-input-dial-code-color;
}

.iti .iti__dial-code:hover {
  color: @intl-tel-input-dial-code-color;
}

@media (min-width: @screen-sm) {
  .form-settings .iti {
    max-width: 380px;
  }
}

.iti__selected-flag {
  z-index: 1000;
}