.daterangepicker {
  color: @colorOnLight;
}

.daterangepicker .ranges li:hover {
  background-color: @hoverColorDropdown  !important;
}

.daterangepicker .ranges li:focus {
  //color: #FFFFFF;
  background-color: @activeColor  !important;
  /* EJ to fix - not working properly */
}

.daterangepicker .ranges li:active {
  color: #FFFFFF;
  background-color: @activeColor  !important;
  /* EJ to fix - not working properly */
}

.report-range {
  color: @colorOnLight;
  background: #FFFFFF;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid @borderColorLight;
  width: 100%;
}

.report-range-wrapper {
  display: flex;
}

.report-range .prefix-label {
  float: left;
  padding-left: 1px;
  padding-top: 1px;
  color: @colorPlaceHolder;
}