.video-container {
  margin: 0 auto;
  border: 0;
  padding: 0;
  //min-height: 360px;
  width: 100%;
}

.video-container-item {
  text-align: center;
}

@media (max-width: 400px) {
  .redactor-editor {
    padding: 10px;
  }
}

.redactor-styles {
  color: #222 !important;
}

.redactor-styles em.fa, 
.redactor-styles i.fa {
  font-style: normal;
  text-align: left;
  //width: 1.28571429em;
}

#redactor-modal-overlay {
  background-color: @background;
  opacity: 0.9;
}

.redactor-styles address,
.redactor-styles blockquote,
.redactor-styles dl,
.redactor-styles figure,
.redactor-styles hr,
.redactor-styles ol,
.redactor-styles p,
.redactor-styles pre,
.redactor-styles table,
.redactor-styles ul {
  margin-bottom: 5px;
}

.content-title-editor .redactor-styles address,
.content-title-editor .redactor-styles blockquote,
.content-title-editor .redactor-styles dl,
.content-title-editor .redactor-styles figure,
.content-title-editor .redactor-styles hr,
.content-title-editor .redactor-styles ol,
.content-title-editor .redactor-styles p,
.content-title-editor .redactor-styles pre,
.content-title-editor .redactor-styles table,
.content-title-editor .redactor-styles ul {
  margin-bottom: 0px;
}

#redactor-modal #redactor-modal-body label.check-box-label {
  display: inline;
  padding-left: 10px;
}

#redactor-modal #redactor-modal-body input[type=checkbox], 
#redactor-modal #redactor-modal-body input[type=radio] {
  margin-top: 10px;
}

.redactor-dropdown.redactor-dropdown-box-specialchars {
  max-width: 126px;
  min-width: 126px;
}

.redactor-dropdown.redactor-dropdown-box-specialchars .redactor-dropdown-cell-square {
  line-height: 42px; 
  width: 42px; 
  height: 42px;
  display: inline-block;
  font-size: 1.5em;
  text-align: center;
}

.redactor-dropdown.redactor-dropdown-box-specialchars .redactor-dropdown-cell-square > a {
  width: 42px;
  height: 42px;
  padding: 0;
}

#redactor-modal #redactor-modal-body .bootstrap-select > .dropdown-menu {
  max-width: 75%;
}