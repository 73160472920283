
.grid-contents {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (max-width: @screen-xs-max) {
  .grid-contents {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Grid and Grid item */

.grid-sizer {
  width: 227.333px; 
}

.grid-item {
  margin-top: 0;
  margin-right: 17.333px;
  margin-bottom: 18px;
  padding: 10px;
  width:210px;
  height:176px;
}

.grid-item.grid-item-tall {
  height:245px;
}

@media (min-width: @screen-lg) {
  .grid-contents {
    margin-left: 15px;
    margin-right: 15px;
  }

  .grid-sizer {
    width: 268.575px;
  }

  .grid-item {
    width:245px;
    height:205px; 
    margin-bottom: 23px;
    margin-right: 23px;
  }

  .grid-item.grid-item-tall {
    height:268px;
  }
}

@media (min-width: @screen-xl) {
  .grid-contents {
    margin-left: 115px;
  }

  /*.grid-sizer {
    width: 268.575px;
  }

  .grid-item {
    width:245px;
    height:205px; 
    margin-bottom: 23px;
    margin-right: 23px;
  }

  .grid-item.grid-item-tall {
    height:268px;
  }*/
}


.grid-item-separator {
  width: 230px;
  height: 30px;
  margin-left: -10px;
}

@media (min-width: 499px) {
  .grid-item-separator {
    width: 460px;
    height: 30px;
    margin-left: -10px;
  }
}

@media (min-width: @screen-xs-sm) {
  .grid-item-separator {
    width: 680px;
    height: 30px;
    margin-left: -10px;
  }
}

@media (min-width: @screen-md) {
  .grid-item-separator {
    width: 910px;
    height: 30px;
    margin-left: -10px;
  }
}

@media (min-width: @screen-lg) {
  .grid-item-separator {
    width: 1068px;
  }
}

.grid-item-empty-separator {
  height: 15px;
  margin-bottom: 0px;
  padding: 5px;
}

.grid-item-action {
  width: 210px;
  height: 176px;
  top: 0;
  left: 0;
  position: absolute;  
  background-color: @gridBackground;
  box-shadow: 0px 2px 4px 0px @grid-item-shadow;
  border-radius: 8px;
  transition: all 140ms ease-out;
}

.grid-item-action.grid-item-action-tall {
  height:245px;
}

.grid-item-action.grid-item-action-create {
  background-color: @btnBackground;
}

.grid-item-action-create-text {
  text-align: center;
  height: 100%;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  font-weight: 500;
  padding-top: 65px;
  padding-left: 20px;
  padding-right: 20px;
}

.grid-item-action-create-text > p {
  padding-top: 15px;
}

.grid-item-disabled {
  background: @grid-item-disabled-background;
  opacity: 0.5;
  width: 210px;
  height: 176px;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 8px;
  z-index: 1001;
}

.grid-item-stack:before,
.grid-item-stack:after {
  content: '';
  height: 176px;
  position: absolute;
  border-radius: 8px;
}

.grid-item-stack:before {
  width: 190px;
  background: @grid-item-stack-before-color;
  z-index: -1;
  top: -5px;
  left: 10px;
  box-shadow: 0px -1px 2px rgb(46 46 46 / 10%);
}

.grid-item-stack:after {
  width: 170px;
  background: @grid-item-stack-after-color;
  z-index: -2;
  top: -10px;
  left: 20px;
  box-shadow: 0px -1px 2px rgb(95 95 95 / 10%);
}

@media (min-width: @screen-lg) {
  .grid-item-action {
    width: 245px;
    height: 205px;
  }
  
  .grid-item-action.grid-item-action-tall {
    height:268px;
  }

  .grid-item-action-create {
    padding-top: 75px;
  }

  .grid-item-action-create-text {
    padding-top: 75px;
    font-size: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .grid-item-action-create-text > p {
    padding-top: 0;
  }
  
  .grid-item-disabled {
    width: 245px;
    height: 205px;
  }

  .grid-item-stack:before,
  .grid-item-stack:after {
    height: 205px;
  }

  .grid-item-stack:before {
    width: 225px;
  }

  .grid-item-stack:after {
    width: 205px;
  }
}


.grid-item > * {
  margin: 0;
  padding: 0;
}

.grid-item-action:hover,
.grid-item-actions-button:hover + .grid-item-action {
  box-shadow: 0px 0px 3px 4px @gridItemHoverColor;
}

.grid-item .grid-item-thumbnail {
  position: absolute;
  border-radius: 8px 8px 0px 0px;
  width:210px;
  height:120px;
  left: 0;
  top: 0;
  overflow: hidden;
  border: @gridThumbnailBorder;
  background-color: @gridImgBackground;
}
.grid-item .grid-item-thumbnail img {
  position: absolute;
  left: 50%;
  height: 100%;
  width: auto;
  transform: translateX(-50%);
}

.grid-item .grid-item-top-left-label {
  z-index: 1001;
  position: absolute;
  left: 2.80%;
  top: 5%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  font-size: 74%;
  line-height: 1.4;
}

.grid-item .grid-item-title-container {
   top: 120px;
   left: 0;
   width: 210px;
   height: 65px;
   position: absolute;
}

.grid-item-info-container {
  position: absolute;
  top: 176px;
  width: 210px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 0.9em;
}

.grid-item-info-container > div {
  padding-top: 3px;
}

.grid-item .grid-item-title {
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 46%;
  transform: translate(0,-50%);
}

.hyphens-none {
  hyphens: none;
}

.hyphens-manual {
  hyphens: manual;
}

.hyphens-auto {
  hyphens: auto;
}

.grid-item .grid-item-title .fa {
  font-size: 0.85em;
  text-align: left;
  width: 1.28571429em;
}

.grid-item .grid-item-title p {
  margin: 0;
}

.grid-item .grid-item-state-icon {
  z-index: 1001;
  position: absolute;
  top: 0;
  left: 100%;
  font-size: 32px;
  transform: translate(-65%, -35%);  
}

.grid-item-deferred-fadeIn {
  opacity: 0; // don't show at start, it should be animated during an event
}

@media (min-width: @screen-lg) {
  .grid-item .grid-item-thumbnail {
    width:245px;
    height:140px;
  }

  .grid-item .grid-item-top-left-label {
    font-size: 80%;
  }

  .grid-item .grid-item-title-container {
    top: 140px;
    width: 245px;
  }

  .grid-item-info-container {
    top: 196px;
    width: 245px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1em;
  }

  .grid-item-info-container > div {
    padding-top: 5px;
  }

  .grid-item .grid-item-title {
    font-size: 16px;
    line-height: 18px;
    top: 50%;
  }

  .grid-item .grid-item-state-icon {
    font-size: 34px;
  }
}

.grid-item .grid-item-state-icon-incomplete {
  color: @grid-item-incomplete-color;
}
.grid-item .grid-item-state-icon-toredo {
  color: @grid-item-toredo-color;
}
.grid-item .grid-item-state-icon-toredo > .fa-stack {
  font-size: 0.5em;
}
.grid-item .grid-item-state-icon-toredo > .fa-stack > .fa-repeat {
  font-size: 1.15em;
}
.grid-item .grid-item-state-icon-completed {
  color: @grid-item-completed-color;
}

.grid-item .grid-item-state-icon-bg {
  position: absolute;  
  z-index: -1;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 50%;
  background: #FFFFFF;
  transform: translate(-50%,-50%);
  border-radius: 12px;
  box-shadow: 0px 0px 10px 1px @grid-item-icon-shadow;
}

.grid-item-actions-button {
  bottom: 0;
  right: 0;
  position: absolute;
  margin-right: 10px;
  margin-bottom: 10px;
  z-index: 2;
}