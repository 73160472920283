.form-tags .tag {
  font-size: 14px;
  font-weight: normal;
  display: block;
  width: 182px; 
  padding: 10px 8px 10px;
  white-space: normal;
}

@media (min-width: @screen-sm) {
  .form-tags .tag {
    width: 210px;
  }
}


.form-tags .tag-row {
  padding-bottom: 15px; 
}

.form-tags .tag-container {
  margin: 0 auto;
  display: table-row;
  width: 100%;
}
.form-tags .settings-tag-container {
  width: 65%;
}

.form-tags .tag-label-container {
  padding-right: 15px;
  float: left;
}

.form-tags .tag-checkbox-container {
  float: left;
}

.col-tags {
  display: table;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .form-tags .tag {
    font-size: 16px;
    font-weight: normal;
    display: block;
    width: 250px; 
    padding: 9px 8px 9px;
  }
}