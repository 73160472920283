/* Mosaik Labels */

/* Bootstrap Override - Modifications to bootstrap classes, do NOT rename */

/* Label Colors */
//** Default label background color
@label-default-bg:            @label-default-color;
//** Primary label background color
@label-primary-bg:            @label-primary-color;
//** Success label background color
@label-success-bg:            @label-success-color;
//** Info label background color
@label-info-bg:               @label-info-color;
//** Warning label background color
@label-warning-bg:            @label-warning2-color;
//** Danger label background color
@label-danger-bg:             @label-danger-color;
//** Neutral label background color
@label-neutral-bg:            @label-neutral-color;
//** "Live" label background color
@label-live-bg:               @label-live-color;

//** Default label text color
@label-color:                 @colorOnDark;
//** Default text color of a linked label
@label-link-hover-color:      @hoverColor;

/* Labels Mosaik */

.label-mosaik {
  display: inline;
  padding: .2em .8em .2em;
  font-size: 70%;
  font-weight: 700;
  line-height: 1.5;
  color: @label-color;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 1em;
}

/* Labels Variant */
.label-mosaik-variant(@varColor) {
  background-color: @varColor;

  &[href] {
    &:hover,
    &:focus {
      background-color: lighten(@varColor, 10%);
    }
  }
}

/* Colors */

/* Contextual Variations (linked labels get darker on :hover) */
.label-mosaik-default {
  .label-mosaik-variant(@label-default-bg);
}

.label-mosaik-primary {
  .label-mosaik-variant(@label-primary-bg);
}

.label-mosaik-success {
  .label-mosaik-variant(@label-success-bg);
}

.label-mosaik-info {
  .label-mosaik-variant(@label-info-bg);
}

.label-mosaik-warning {
  .label-mosaik-variant(@label-warning-bg);
}

.label-mosaik-danger {
  .label-mosaik-variant(@label-danger-bg);
}

.label-mosaik-neutral {
  .label-mosaik-variant(@label-neutral-bg);
}

.label-mosaik-live {
  .label-mosaik-variant(@label-live-bg);
  border-radius: 8px;
}