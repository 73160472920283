.container-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 25px;
  width: 100%;
  color: @colorMuted;
}

.container-footer a {
  color: @colorMuted;
}

.container-footer a:hover {
  color: @hoverColor
}

.container-footer p .fa-external-link {
  font-size: 0.8em;
}

.footer-brand { 
  padding-top: 20px;
}

.footer-links {
  padding-top: 10px;
}

.footer-text {
  padding-top: 10px;
}

@media (min-width: @screen-sm) {
  .container-footer {
    left: auto;
    padding-left: 15px;
    padding-right: 0px;
    width: 698px;
  }
}

@media (min-width: @screen-md) {
  .container-footer {
    width: 920px;
  }
}

@media (min-width: @screen-lg) {
  .container-footer {
    width: 1120px;
  }
}

@media (min-width: @screen-xl) {
  .container-footer {
    width: 1380px;
  }
}